import {Form, Formik} from 'formik';
import React from 'react';
import { Div } from 'glamorous';
import { translate,WithNamespaces } from 'react-i18next';
import { Link,RouteComponentProps } from 'react-router-dom';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { IStateProps, IDispatchProps } from './LoginFormContainer';
import constants from '../../../../utils/constants';
import UniField from  '../../../Wizard/components/UniFields/UniField';
import * as yup from 'yup';

export interface ILoginFormProps extends WithNamespaces {
    login: Function;
}

interface ILoginFormValues {
    email: string;
    password: string;
}

export class LoginForm extends React.Component<IStateProps & IDispatchProps & RouteComponentProps & ILoginFormProps> {

    handleSubmit = (values: ILoginFormValues, form: Formik) => {
        return this.props.login(values.email, values.password, this.props.lang)
            .then(
                (action: any) => {
                    if (action && action.error) {
                        form.setErrors({
                            'email': 'empty',
                            'password': action.error[0],
                        });
                        form.setSubmitting(false);
                    }
                }
            );
    };

    createValidationSchema() {
        return yup.object().shape({
            email: yup.string()
                .email('global.email')
                .required('global.notBlank'),
            password: yup.string()
                .required('global.notBlank'),
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Div>
                <h3>{t('login.title.label')}</h3>
                <p>{t('login.title.placeholder')}</p>

                <Formik
                    initialValues={{email: '', password: ''}}
                    onSubmit={this.handleSubmit.bind(this)}
                    isInitialValid={true}
                    validationSchema={this.createValidationSchema()}
                >
                    {props => {
                        return (
                            <Div>
                                <Form>
                                    <UniField name="email" textKey="login.email" />
                                    <UniField name="password" type="password" textKey="login.password" />
                                    <Button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        {t('login.button.label')}
                                    </Button>
                                </Form>
                                <p>
                                    {t('login.notRegistered.label')}{' '}
                                    <Link to={constants.ROUTES.AUTH.SIGN_UP}>
                                        {t('login.notRegistered.placeholder')}
                                    </Link>
                                </p>
                                <p>
                                    <Link to={constants.ROUTES.AUTH.RESET_PASSWORD_REQUEST}>
                                        {t('login.forgotPassword.label')}
                                    </Link>
                                </p>
                            </Div>
                        )
                    }}
                </Formik>
            </Div>
        )
    }
}

export default translate('translations')(LoginForm);