import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ResetPasswordRequestForm from './ResetPasswordRequestForm';
import usersActions from '../../actions/usersActions';

const mapStateToProps = (state: any) => {
	return {
        lang: state.app.lang.lang,
	};
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {
		forgotPasswordRequest: (email:  string, language: string) => {
			return dispatch(usersActions.resetPasswordRequest(email, language));
		},
	};
};

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(ResetPasswordRequestForm));