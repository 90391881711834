import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import constants from '../../../../utils/constants';
import history from '../../../../utils/history';
import { trActions } from '../../../Common/actions/trActions';
import { ITaxReturn } from "../../../Dashboard/components/TaxReturn/ITaxReturn";
import { wizardActions } from '../../actions/wizardActions';
import StepZeroForm from './StepZeroForm';
import { IStepContainerProps } from '../../interfaces/IStepContainerProps';

interface IStepZeroFormContainerProps extends IStepContainerProps {
	createEmpty(taxReturn: ITaxReturn): Function;
	newTaxReturn: ITaxReturn;
}

class StepZeroFormContainer extends React.Component<WithNamespaces & IStepZeroFormContainerProps & RouteComponentProps> {

	componentDidUpdate() {
		const { t, newTaxReturn } = this.props;
		if (newTaxReturn && newTaxReturn.id) {
			// const query = newTaxReturn.year === 2021 ? '?xml-warning' : '';
			const query = '';
			history.push(constants.ROUTES.WIZARD.STEP[1] + '/' + newTaxReturn.id + query);
		}
	}

	componentDidMount() {
		this.props.setStep(0);
	}

	render() {
		const { t } = this.props;
		return (
			<StepZeroForm {...this.props} />
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
		loggedUser: state.app.auth.user,
		taxReturn: state.entities.taxReturn,
		newTaxReturn: state.entities.newTaxReturn
	};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {
		createEmpty: (taxReturn: ITaxReturn) => {
			return dispatch(trActions.createEmpty(taxReturn));
		},
		setStep: (step: number) => {
			return dispatch(wizardActions.onStepChange(step));
		}
	};
};

export default translate('translations')(withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(StepZeroFormContainer)) as any);
