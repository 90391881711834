import { ErrorMessage } from 'formik';
import React from 'react';

interface IFormFieldErrorProps {
    fieldName: string;
    textKey: string;
    isValidating: boolean;
    t: Function;
}

class FormFieldError extends React.Component<IFormFieldErrorProps> {

    renderErrorFn = (message) => {
        const { t, textKey } = this.props;
        const fallbackMsg = 'global.invalid';

        if (message instanceof Array && message.length > 0) {
            message = message.length ? message[0] : fallbackMsg;
        }

        let errorText = t(fallbackMsg);
        if (typeof message === 'string') {
            if (message ===  'empty') {
                errorText = '';
            } else if (message.startsWith('global')) {
                errorText = t(`validations.${message}.label`);
            } else if (!message.includes(' ')) {
                errorText = t(`validations.${textKey}.${message}.label`);
            } else {
                errorText = message;
            }
        }

        return <div className="errorMessage">{errorText}</div>
    };

    render() {
        const { t, isValidating, fieldName } = this.props;

        if (isValidating) {
            return null
        }

        return (
            <ErrorMessage name={fieldName} render={this.renderErrorFn} />
        )
    }

}

export default FormFieldError;