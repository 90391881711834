import { langService } from '../services/langService';
import langConstants from './langConstants';
import i18next from '../langs/i18n';
import { getI18n } from 'react-i18next';

const i18n = getI18n();

export const langActions = {
    load
};

function load(lang: string) {
	return (dispatch: Function) => {

		dispatch({type: langConstants.LOAD.REQUEST, lang});

		return langService.load(lang)
			.then(
				langSetResult => {
                    const i18next = getI18n();
                    i18next.addResourceBundle(lang, 'translations', langSetResult, true, true);
                    i18n.changeLanguage(lang);
                    i18next.changeLanguage(lang);
                    const result = {
                        langSetResult,
                        lang
                    };

                    langService.save(lang);
                    dispatch({type: langConstants.LOAD.SUCCESS, result});
                    return result;
				},
				(error: any) => dispatch({type: langConstants.LOAD.FAILURE, error}),
			);
	}
}
