import { apiConfig } from '../../../config/apiConfig';
import apiService from '../../../services/apiService';
import { ITaxReturn } from '../../Dashboard/components/TaxReturn/ITaxReturn';
import { API } from '../../../utils/apiConstants';
import {taxReturn} from "../../../reducers/entities/taxReturn";

export const trService = {
    loadAll,
    load,
    loadMy,
    createEmpty,
    changeYear,
    remove,
    patch,
    validate,
    xmlAttachments,
    certifiedCheckAttachments,
    onMyBehalfAttachments,
    pay,
    payAdditionalServices,
    attachFile,
    deleteAttachment,
    getAttachmentThumbnailUrl,
    getAttachmentLargeThumbnailUrl,
    getAttachmentToken,
    getAttachmentUrl,
    copy,
	createSupplementary
};

function loadAll() {
	return apiService.get(API.TAX_RETURN + '/');
}

function load(id: string) {
	return apiService.get(`${API.TAX_RETURN}/${id}`);
}

function remove(id: string) {
    return apiService.remove(`${API.TAX_RETURN}/${id}`);
}

function loadMy(personId: string) {
    return apiService.get(`${API.PERSONS}/${personId}/tax-returns`);
}

function changeYear(id: string, year: number) {
	return apiService.post(`${API.TAX_RETURN}/${id}/year`, {
		year,
	}).then(
		response => {
			return response;
		}
	);
}

function createEmpty(data: ITaxReturn) {
    return apiService.post(API.TAX_RETURN + '/', {...data});
}

function patch(id: string, data: any, supressToastErrors = false) {
	let cleanData = {...data};
	delete cleanData.id;
	delete cleanData.state;
	delete cleanData.updatedAt;
	delete cleanData.createdAt;
	delete cleanData.calculation;
	delete cleanData.language;
    return apiService.patch(`${API.TAX_RETURN}/${id}`, cleanData, undefined, supressToastErrors);
}

function validate(id: string, step: number, supressToastErrors = false) {
	return apiService.post(`${API.TAX_RETURN}/${id}/validate/${step}`, undefined, undefined, supressToastErrors);
}

function xmlAttachments(id: string) {
    return apiService.get(`${API.TAX_RETURN}/${id}/xml-attachments`);
}

function certifiedCheckAttachments(id: string) {
  return apiService.get(`${API.TAX_RETURN}/${id}/certified-check-attachments`);
}

function onMyBehalfAttachments(id: string) {
    return apiService.get(`${API.TAX_RETURN}/${id}/on-my-behalf-attachments`);
}

async function pay(id: string, language?: string, supressToastErrors = false): Promise<{id: string, gatewayUrl: string}> {
    return apiService.post(`${API.TAX_RETURN}/${id}/pay${language == undefined ? '' : `/${language}`}`, undefined, undefined, supressToastErrors);
}

async function payAdditionalServices(id: string, language?: string, supressToastErrors = false): Promise<{id: string, gatewayUrl: string}> {
	return apiService.post(`${API.TAX_RETURN}/${id}/pay-additional-services${language == undefined ? '' : `/${language}`}`, undefined, undefined, supressToastErrors);
}

function attachFile(id: string, type: string, file: any) {
	return apiService.uploadFile(`${API.TAX_RETURN}/${id}/attachments/${type}`, createFormDataForFile(file));
}

function createFormDataForFile(file) {
	const formData = new FormData();
	formData.append('file', file);

	return formData;
}

function deleteAttachment(id: string, taskAttachmentId) {
	return apiService.remove(`${API.TAX_RETURN}/${id}/attachments/${taskAttachmentId}`);
}

function getAttachmentUrl(id: string, attachmentId, download = null) {
	let url = apiConfig.url
		+ `${API.TAX_RETURN}/${id}/attachments/${attachmentId}`;

	if (download) {
		url += '?download';

		if (download !== '') {
			url += '=' + encodeURIComponent(download);
		}
	}

	return url;
}

function getAttachmentThumbnailUrl(id: string, attachmentId) {
	return apiConfig.url
		+ `${API.TAX_RETURN}/${id}/attachments/${attachmentId}/thumbnail?filter=task_attachment_file_small_2x`;
}

function getAttachmentLargeThumbnailUrl(id: string, attachmentId) {
	return apiConfig.url
		+ `${API.TAX_RETURN}/${id}/attachments/${attachmentId}/thumbnail?filter=task_attachment_file_large`;
}


function getAttachmentToken(id: string, attachmentId) {
	return apiService.get(`${API.TAX_RETURN}/${id}/attachments/${attachmentId}/token`);
}

function copy(id: string, year: string, supressToastErrors = false) {
  return apiService.post(`${API.TAX_RETURN}/${id}/copy`, { year }, undefined, supressToastErrors);
}

function createSupplementary(id: string) {
	return apiService.post(`${API.TAX_RETURN}/${id}/create-supplementary`, {}, undefined, false);
}

export default trService;