import createReducer from '../../store/createReducer';
import trConstants from "../../modules/Common/actions/trConstants";

function loadSuccess(state, action) {
	return action.result;
}

function loadFailure(state, action) {
	const { result } = action;
	return {};
}

export const xmlAttachments = createReducer({}, {
    [trConstants.XML_ATTACHMENTS.SUCCESS]: loadSuccess,
    [trConstants.XML_ATTACHMENTS.FAILURE]: loadFailure,
	[trConstants.XML_ATTACHMENTS.REQUEST]: (state, action) => {
		return {};
    },
});