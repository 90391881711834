import wizardConstants from './wizardConstants';
import overPaymentService from '../../Common/services/OverPaymentService';

export const wizardActions = {
    onStepChange,
    overPaymentSubmit
};

function onStepChange(step: number, direction?: string) {
	return (dispatch: Function) => {
        let newStep = step;
        if (direction === 'next') {
            newStep = Math.min(step + 1, wizardConstants.NUMBER_OF_STEPS);
        }
        if (direction === 'prev') {
            newStep = Math.max(step - 1, 0);
        }
        dispatch({type: wizardConstants.SET_PAGE.REQUEST, step});
        dispatch({type: wizardConstants.SET_PAGE.SUCCESS, newStep});
	}
}

function overPaymentSubmit(values: any) {
	return (dispatch: Function) => {
        dispatch({type: wizardConstants.OVER_PAYMENT_SUBMIT.REQUEST, values});

		return overPaymentService.submit(values)
			.then(
				result => {
					dispatch({type: wizardConstants.OVER_PAYMENT_SUBMIT.SUCCESS, result});
					return result;
				},
				(error: any) => dispatch({type: wizardConstants.OVER_PAYMENT_SUBMIT.FAILURE, error}),
            );

	}
}