import React from 'react';
import glamorous, { Div, Span, Img } from 'glamorous';
import theme from '../../styles/theme';
import {faQuestionCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithNamespaces, translate } from 'react-i18next';
import {Button, Modal, ModalBody, ModalFooter, Popover, PopoverBody} from "reactstrap";
import appConfig from "../../config/appConfig";
import {ITaxReturn} from "../../modules/Dashboard/components/TaxReturn/ITaxReturn";

interface IHelpIconProps {
    helpKey: string;
    targetName?: string;
    taxReturn?: ITaxReturn;
    t: Function;
}

interface IHelpIconState {
    popoverOpened: boolean;
    modalOpened: boolean;
}

class HelpIcon extends React.Component<IHelpIconProps, IHelpIconState> {

    readonly state = {
        popoverOpened: false,
        modalOpened: false,
    };

    togglePopover = () => {
        this.setState(prevState => ({
            popoverOpened: !prevState.popoverOpened,
        }));
    };

    toggleModal = () => {
        this.setState(prevState => ({
            popoverOpened: false,
            modalOpened: !prevState.modalOpened,
        }));
    };


    render() {
        const {helpKey, targetName, taxReturn, t} = this.props;

        const target = (targetName ? targetName : helpKey).replace(/\./g, '-') + '-target';
        const helpText= {
            __html: t(helpKey + '.helpText', {defaultValue: '', taxReturnYear: taxReturn ? taxReturn.year : '', followingYear: taxReturn ? taxReturn.year + 1 : ''}),
        };
        const helpImage = t(helpKey + '.helpImage', {defaultValue: ''});

        return (
            <HelpIconDiv id={target} onClick={this.togglePopover}>
                <FontAwesomeIcon icon={faQuestionCircle} style={{color: theme.colors.green}} />
                <Popover placement='bottom' isOpen={this.state.popoverOpened} target={target} togglePopover={this.togglePopover}>
                    <PopoverBody>
                        <CloseButton onClick={this.togglePopover}><FontAwesomeIcon size="lg" icon={faTimesCircle} style={{color: theme.colors.gray}} /></CloseButton>
                        {helpImage !== '' && helpText.__html !== '' && <HelpImg
                            onClick={this.toggleModal}
                            src={appConfig.formFieldsImgBaseUrl + helpImage} />}
                        {helpImage !== '' && helpText.__html === '' && <FullHelpImg
                            onClick={this.toggleModal}
                            src={appConfig.formFieldsImgBaseUrl + helpImage} />}
                        {helpText.__html !== '' && <Span dangerouslySetInnerHTML={helpText} />}
                        <Span className="cleared"> </Span>
                    </PopoverBody>
                </Popover>
                {helpImage !== '' && <Modal isOpen={this.state.modalOpened} toggle={this.toggleModal} size="lg">
                    <ModalBody>
                        <ModalImg src={appConfig.formFieldsImgBaseUrl + helpImage} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>{t('close.label')}</Button>
                    </ModalFooter>
                </Modal>}
            </HelpIconDiv>
        );
    }
};

const HelpIconDiv = glamorous(Div)({
    display: 'inline-block',
    marginLeft: '10px',
    verticalAlign: 'top',
});

const HelpImg = glamorous(Img)({
    float: 'left',
    maxWidth: '50%',
    marginRight: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
});

const FullHelpImg = glamorous(Img)({
    width: '100%',
    cursor: 'pointer',
});

const ModalImg = glamorous(Img)({
    width: '100%',
})


const CloseButton = glamorous(Div)({
    position: 'absolute',
    top: '-0.5em',
    right: '-0.5em',
    backgroundColor: theme.colors.grayDark,
    borderRadius: '1em',
});

export default HelpIcon;