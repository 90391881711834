export const appConfig = {
	webBaseUrl: process.env.REACT_APP_WEB_URL,
    formFieldsImgBaseUrl: process.env.REACT_APP_WEB_URL + '/uploads/form_fields/',
    goPayEmbedUrl: process.env.REACT_APP_GOPAY_EMBED_URL,
    googleAnalyticsId: process.env.REACT_APP_GA_ID,
    fbPixelId: process.env.REACT_APP_FB_PIXEL_ID,
    includeSmartsUpp: process.env.REACT_APP_INCLUDE_SMARTSUPP,
    includeGTM: process.env.REACT_APP_INCLUDE_GTM,
};

export default appConfig;