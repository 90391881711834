import history from '../../../utils/history';
import { ITaxReturn } from '../components/TaxReturn/ITaxReturn';
import listConstants from './listConstants';

export const listActions = {
    onEdit,
    onDetail,
    onDownload
};

function onEdit(item: ITaxReturn, isNewTaxReturn: boolean) {
    return (dispatch: Function) => {
        dispatch({type: listConstants.GOTOEDIT, item});
        history.push('/wizard/step-one/' + item.id/* + (isNewTaxReturn && item.year === 2021 ? '?xml-warning' : '')*/);
    }
}

function onDownload(item: ITaxReturn) {
    return (dispatch: Function) => {
        dispatch({type: listConstants.GO_TO_DOWNLOAD, item});
        history.push('/download/' + item.id);
    }
}

function onDetail(item: ITaxReturn) {
	return (dispatch: Function) => {
        dispatch({type: listConstants.SHOWDETAIL, item});
	}
}
