import i18n from '../../../langs/i18n';
import { addMessage, messagesConstants } from '../../../services/flashMessages';
import usersService from '../../../services/usersService';
import constants from '../../../utils/constants';
import history from '../../../utils/history';
import usersConstants from './usersConstants';

export const usersActions = {
	register,
	confirm,
	login,
	logout,
	resetPasswordRequest,
	resetPassword,
	loadUser
};

function register(email: string, firstName: string, lastName: string, password: string, termsOfService: boolean, personalDataProcessing: boolean, isSubscribedToMailNotifications: boolean, language: string, invitation = null) {
	return (dispatch: Function) => {
		const user = { email, firstName, lastName };

		dispatch({type: usersConstants.SIGN_UP.REQUEST, user});

		return usersService.register(email, firstName, lastName, password, termsOfService, personalDataProcessing, isSubscribedToMailNotifications, language, invitation)
			.then(
				result => {
					dispatch({type: usersConstants.SIGN_UP.SUCCESS, user});

					if (result.enabled) {
						usersService.getLoggedUser().then(user => {
							dispatch({type: usersConstants.LOGIN.SUCCESS, user});
							history.push('/');
						});
					} else {
						history.push(constants.ROUTES.AUTH.CONFIRM);
					}

					return result;
				},
				(error: any) => dispatch({type: usersConstants.SIGN_UP.FAILURE, error}),
			);
	}
}

function confirm(token: string, language: string) {
	return (dispatch: Function) => {
		dispatch({type: usersConstants.CONFIRM.REQUEST, token});

		return usersService.confirm(token, language)
			.then(
				() => {
					dispatch({type: usersConstants.CONFIRM.SUCCESS, token});
					history.push(constants.ROUTES.AUTH.LOGIN);
				},
				(error: any) => dispatch({type: usersConstants.CONFIRM.FAILURE, error}),
			);
	}
}

function login(email: string, password: string, language: string) {
	return (dispatch: Function) => {
		const user = { email };

		dispatch({type: usersConstants.LOGIN.REQUEST, user});

		return usersService.login(email, password, language)
			.then(
				user => {
					dispatch({type: usersConstants.LOGIN.SUCCESS, user});
					history.push(constants.ROUTES.DASHBOARD);
				},
				(error: any) => dispatch({type: usersConstants.LOGIN.FAILURE, error}),
			);
	}
}

function logout(language) {
	return (dispatch: Function) => {
		dispatch({type: usersConstants.LOGOUT.REQUEST});
		return usersService.logout(language)
			.then(
				() => {
					dispatch({type: usersConstants.LOGOUT.SUCCESS});
					history.push(constants.ROUTES.AUTH.LOGIN);
				},
				(error: any) => dispatch({type: usersConstants.LOGOUT.FAILURE, error})
			);
	}
}

function resetPasswordRequest(email: string, language: string) {
	return (dispatch: Function) => {
		dispatch({type: usersConstants.RESET_PASSWORD_REQUEST.REQUEST, email});

		return usersService.resetPasswordRequest(email, language)
			.then(
				() => {
					dispatch({type: usersConstants.RESET_PASSWORD_REQUEST.SUCCESS, email});
					addMessage(i18n.t('messages.passwordResetRequestSent.label'), messagesConstants.TYPE_SUCCESS);
					history.push(constants.ROUTES.AUTH.LOGIN);
				},
				(error: any) => dispatch({type: usersConstants.RESET_PASSWORD_REQUEST.FAILURE, error}),
			);
	}
}

function resetPassword(token: string, password: string, language: string) {
	return (dispatch: Function) => {
		dispatch({type: usersConstants.RESET_PASSWORD.REQUEST, token});

		return usersService.resetPassword(token, password, language)
			.then(
				() => {
					dispatch({type: usersConstants.RESET_PASSWORD.SUCCESS, token});
					addMessage(i18n.t('messages.passwordResetComplete.label'), messagesConstants.TYPE_SUCCESS);
					history.push(constants.ROUTES.AUTH.LOGIN);
				},
				(error: any) => dispatch({type: usersConstants.RESET_PASSWORD.FAILURE, error}),
			);
	}
}

function loadUser(userId: string) {
	return (dispatch: Function) => {
		dispatch({type: usersConstants.LOAD_USER.REQUEST});

		const result = usersService.getUser(userId);

		result.then(
			user => dispatch({type: usersConstants.LOAD_USER.SUCCESS, user}),
			(error: any) => dispatch({type: usersConstants.LOAD_USER.FAILURE, error}),
		);

		return result;
	}
}

export default usersActions;