import catalogConstants from '../../components/CatalogComboField/catalogConstants';
import createReducer from '../../store/createReducer';

function loadSuccess(state, action) {
    const newState = { ...state };
    newState[action.result.catalogName] = action.result.response;
	return newState;
}

function loadFailure(state, action) {
	const { result } = action;
	return {};
}

export const catalogs = createReducer({}, {
    [catalogConstants.LOAD.SUCCESS]: loadSuccess,
    [catalogConstants.LOAD.FAILURE]: loadFailure,
	[catalogConstants.LOAD.REQUEST]: (state, action) => {
		return {};
    },
});