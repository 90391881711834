import {Form, Formik} from 'formik';
import { Div } from 'glamorous';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import FormFieldError from '../../../../components/FormFieldError/FormFieldError';
import * as yup from 'yup';
import UniField from  '../../../Wizard/components/UniFields/UniField';

interface ILoginFormValues {
    password: string;
    passwordConfirm: string;
}

export interface IResetPasswordFormProps {
    resetPassword: Function;
    lang: string;
}

export class ResetPasswordForm extends React.Component<WithNamespaces & IResetPasswordFormProps> {

    handleSubmit = (values: ILoginFormValues, form: Formik) => {
        return this.props.resetPassword(values.password, this.props.lang)
            .then(
                (action: any) => {
                    if (action && action.error) {
                        form.setErrors({
                            'password': action.error[0]
                        });
                        form.setSubmitting(false);
                    }
                }
            );
    };

    createValidationSchema() {
        return yup.object().shape({
            password: yup.string()
                .required('global.notBlank')
                .min(8, 'minLength'),
            passwordConfirm: yup.string()
                .oneOf([yup.ref('password')], 'passwordsDoNotMatch')
                .required('global.notBlank'),
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Div>
                <h3>{t('resetPassword.title.label')}</h3>
                <p>{t('resetPassword.title.placeholder')}</p>

                <Formik
                    initialValues={{password: '', passwordConfirm: ''}}
                    onSubmit={this.handleSubmit}
                    isInitialValid={true}
                    validationSchema={this.createValidationSchema()}
                >
                    {props => {
                        return (
                            <Form>
                                <UniField name="password" type="password" textKey="resetPassword.password" errorByName={true} />
                                <UniField name="passwordConfirm" type="password" textKey="resetPassword.passwordConfirm" errorByName={true} />
                                <Button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    {t('resetPassword.button.label')}
                                </Button>
                            </Form>
                        )
                    }}
                </Formik>
            </Div>
        )
    }
}

export default translate('translations')(ResetPasswordForm);