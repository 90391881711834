import React from 'react';
import { connect } from 'react-redux';
import StepHeadlineInner from "./StepHeadlineInner";
import constants from "../../../../utils/constants";
import wizardConstants from "../../actions/wizardConstants";

const mapStateToProps = (state: any) => {
	const taxReturn = state.entities.taxReturn;
	const numberOfSteps = (taxReturn && taxReturn.state === constants.TAX_RETURN_STATE.PAID)
		? wizardConstants.NUMBER_OF_STEPS
		: wizardConstants.NUMBER_OF_STEPS - 1;

	return {
        step: state.app.wizard.step,
        numberOfSteps,
		taxReturnId: state.entities.taxReturn ? state.entities.taxReturn.id : null,
	};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {};
};

export const StepHeadline = connect(
    mapStateToProps,
    mapDispatchToProps
)(StepHeadlineInner);

export default StepHeadline;
