import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { Div } from 'glamorous';

export class ConfirmUser extends React.Component<WithNamespaces> {

	render() {
		const t: Function = this.props.t;

		return (
			<Div>
				<h3>{t('confirmUser.title.label')}</h3>
				<p>{t('confirmUser.title.placeholder')}</p>
			</Div>
		)
	}
}

export default translate('translations')(ConfirmUser);