import { Field, FieldProps, getIn } from 'formik';
import { FormGroup, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import HelpIcon from "../../../../components/HelpIcon/HelpIcon";
import {Div} from 'glamorous';
import FormFieldError from "../../../../components/FormFieldError/FormFieldError";
import {ITaxReturn} from "../../../Dashboard/components/TaxReturn/ITaxReturn";

interface IUniRadioProps {
    name: string;
    fieldName: string;
    textKey?: string;
    errorTextKey?: string;
    value: string;
    required?: boolean;
    showsError?: boolean;
    validateForm: Function;
    taxReturn: ITaxReturn;
}

class UniRadioInner extends React.Component<WithNamespaces & FieldProps & IUniRadioProps> {
    render () {
        const {
            field,
            textKey,
            errorTextKey,
            value,
            required,
            showsError,
            t,
            form: { errors, touched },
            validateForm,
            taxReturn
        } = this.props;
        const _textKey = textKey ? textKey : field.name;

        const hasHelp = t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''}) !== '';

        const onChange = (eventOrPath) => {
            field.onChange(eventOrPath);
            setTimeout(validateForm,100);
        };

        return (<Field>
            {({ form }) => (<Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                <FormGroup className="checkbox">
                    <Input
                        type="radio"
                        id={`${field.name}-${value}`}
                        {...field}
                        onChange={onChange}
                        checked={field.value === value}
                        value={value}
                    />
                    <Label for={`${field.name}-${value}`}>
                        {t(_textKey + '.label')}{' '}
                    </Label>
                    {hasHelp && <HelpIcon helpKey={_textKey} taxReturn={taxReturn} t={t} />}
                    {showsError && <FormFieldError fieldName={field.name} textKey={errorTextKey ? errorTextKey : _textKey} isValidating={form.isValidating} t={t}/>}
                </FormGroup>
            </Div>)}
        </Field>);
    }
}

const UniRadio: React.SFC<WithNamespaces & IUniRadioProps> = ({ name, ...rest }) => {
    return (
        <Field name={name} {...rest} component={UniRadioInner} />
    )
};

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {};
};

export default translate('translations')(connect(
	mapStateToProps,
	mapDispatchToProps
)(UniRadio)) as any;
