import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import moment, { Moment } from 'moment';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import { Div } from 'glamorous';
import 'react-datepicker/dist/react-datepicker.css';
import { Field, Formik, getIn } from 'formik';
import HelpIcon from "../HelpIcon/HelpIcon";
import FormFieldError from "../FormFieldError/FormFieldError";
import {ITaxReturn} from "../../modules/Dashboard/components/TaxReturn/ITaxReturn";

interface IInlineDatePickerProps {
    value: string;
    emptyLabel: boolean;
    label: string;
    placeholder: string;
    field: any;
    name: string;
    values: any;
    form: any;
    lang: string;
    required?: boolean;
    year: number;
    minYear?: number;
    taxReturn: ITaxReturn;
}

// ------------  CONTAINER ---------------------
const mapStateToProps = (state: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

class DatePickerContainer extends React.Component<WithNamespaces & IInlineDatePickerProps> {

    handleChange(value: Moment, form: Formik) {
        let val;
        if (value) {
            // console.error('selected date', value.format('YYYY-MM-DD'), this.props.name, this.props.values);
            val = value.format('YYYY-MM-DD');
        } else {
            val = null;
        }
        form.setFieldValue(this.props.name, val);
    }

    render() {
        const props = this.props;
        const { placeholder, name, value, t, lang, required, year, minYear, taxReturn} = this.props;

        const hasHelp = t(name + '.helpText', {defaultValue: ''}) !== ''
            || t(name + '.helpImage', {defaultValue: ''}) ;

        return (
            <Field name={props.name}>
                {({ field, form}) => (
                    <Div className={!form.isValidating && getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'error' : ''}>
                        <FormGroup>
                            <Label>
                                {t(name + '.label')}{ required ? '*' : ''}{' '}
                                {hasHelp && <HelpIcon helpKey={name} taxReturn={taxReturn} t={t} />}
                            </Label>
                             <DatePicker
                                selected={value ? moment(value) : null}
                                dateFormat={'YYYY-MM-DD'}
                                onChange={(value: Moment) => this.handleChange(value, form)}
                                isClearable={true}
                                placeholder={placeholder}
                                openToDate={moment(`${year}-01-01`)}
                                minDate={minYear ? moment(`${minYear}-01-01`) : null}
                                maxDate={moment().endOf('year')}
                                showYearDropdown
                                dropdownMode="select"
                                locale={lang}
                            />
                        </FormGroup>
                        <FormFieldError fieldName={field.name} textKey={field.name} isValidating={form.isValidating} t={t}/>
                    </Div>
                )}
            </Field>
        );
    }
}

export default translate('translations')(connect(mapStateToProps, mapDispatchToProps)(DatePickerContainer) as any);