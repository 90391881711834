import apiService from '../../../services/apiService';
import { API } from '../../../utils/apiConstants';

export const overPaymentService = {
	submit
};

function submit(values: any) {
	return apiService.post(`${API.TAX_RETURN}`, values).then(
		response => {
			return response;
		}
	);
}

export default overPaymentService;