import React from 'react';
import glamorous,{Div} from 'glamorous';
import {Col, Row } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import constants from '../../../../../utils/constants';

interface IAbroadIncomesSummaryProps {
    abroadIncomes: Array<any>;
    taxReturnId: string;
    thousandSeparator: string;
    czkSuffix: string;
    t: Function;
}

export default class AbroadIncomesSummary extends React.Component<IAbroadIncomesSummaryProps> {
	render() {
        const { abroadIncomes, taxReturnId, t, thousandSeparator, czkSuffix } = this.props;

        return (<Div className="formSection summary">
            <h3 className="noCheckbox">{t('sections.abroadIncomes.label')}</h3>
            <Div className="subForm">
                <Div>
                    {abroadIncomes.map((employment, index) => (<Div className="summary-block">
                        <Row key={index}>
                            <Col xs="6">
                                {t('abroadIncomes.country.label')}
                            </Col>
                            <Col xs="6">
                                <strong>{abroadIncomes[index].country}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                {t('abroadIncomes.employer.label')}
                            </Col>
                            <Col xs="6">
                                <strong>{abroadIncomes[index].employer}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                {t('abroadIncomes.income.label')}
                            </Col>
                            <Col xs="6">
                                <strong><NumberFormat value={abroadIncomes[index].income} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                {t('abroadIncomes.taxCzk.label')}
                            </Col>
                            <Col xs="6">
                                <strong><NumberFormat value={abroadIncomes[index].taxCzk} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                {t('abroadIncomes.taxLocal.label')}
                            </Col>
                            <Col xs="6">
                                <strong><NumberFormat value={abroadIncomes[index].taxLocal} displayType="text" thousandSeparator={thousandSeparator} /></strong>
                            </Col>
                        </Row>
                    </Div>))}
                </Div>
                <Link to={constants.ROUTES.WIZARD.STEP[1] + '/' + taxReturnId}>{t('editThisSection.label')}</Link>
            </Div>
        </Div>)
	}
}
