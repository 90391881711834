import { combineReducers } from 'redux';

import app from './appReducer';
import entities from './entitiesReducer';
import ui from './uiReducer';

export const rootReducer = combineReducers({
	app,
	entities,
	ui,
});

export default rootReducer;