import { Field, FieldProps, getIn } from 'formik';
import { FormGroup, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import HelpIcon from "../../../../components/HelpIcon/HelpIcon";
import {Div} from 'glamorous';
import {InputType} from "reactstrap/lib/Input";
import FormFieldError from "../../../../components/FormFieldError/FormFieldError";
import {ITaxReturn} from "../../../Dashboard/components/TaxReturn/ITaxReturn";

interface IUniFieldProps {
    name: string;
    fieldName: string;
    textKey?: string;
    type?: string;
    required?: boolean;
    readOnly?: boolean;
    errorByName?: boolean;
    errorTextKey?: string;
    value?: any;
    setFieldValue?: Function;
    setFieldTouched?: Function;
    taxReturn: ITaxReturn;
}

class UniFieldInner extends React.Component<WithNamespaces & FieldProps & IUniFieldProps> {
    render () {
        const {
            field,
            textKey,
            type,
            required,
            readOnly,
            errorByName,
            errorTextKey,
            t,
            value,
            setFieldValue,
            setFieldTouched,
            form: { errors, touched },
            taxReturn
        } = this.props;
        const _type = (type ? type : 'text') as InputType;
        const _textKey = textKey ? textKey : field.name;

        const hasHelp = t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''}) !== '';

        if (value && !field.value && !getIn(touched, field.name)) {
            setFieldValue(field.name, value);
        }

        const onFocus = () => {
            if (setFieldTouched) {
                setFieldTouched(field.name);
            }
        };

        return (<Field>
            {({ form }) => (<Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t(_textKey + '.label')}{ required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon helpKey={_textKey} taxReturn={taxReturn} targetName={field.name} t={t} />}
                    </Label>
                    <Input
                        type={_type}
                        {...field}
                        placeholder={t(_textKey + '.placeholder', {defaultValue: ''})}
                        readOnly={readOnly}
                        onFocus={onFocus}
                    />
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={errorTextKey ? errorTextKey : (errorByName ? field.name : _textKey)} isValidating={form.isValidating} t={t}/>
            </Div>)}
        </Field>);
    }
}

const UniField: React.FunctionComponent<WithNamespaces & IUniFieldProps> = ({ name, ...rest }) => {
    return (
        <Field name={name} {...rest} component={UniFieldInner} />
    )
};

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {};
};

export default translate('translations')(connect(
	mapStateToProps,
	mapDispatchToProps
)(UniField)) as any;
