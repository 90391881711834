import { connect } from 'react-redux';
import HeaderInner from './HeaderInner';
import usersActions from '../../modules/Auth/actions/usersActions';

const mapStateToProps = (state) => {
	return {
		userName: state.app.auth.user.firstName + ' ' + state.app.auth.user.lastName,
        lang: state.app.lang.lang,
	};
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {
		logout: (email: string, name: string, password: string, language: string) => {
			return dispatch(usersActions.logout(language));
		}
	};
};

export const Header = connect(
	mapStateToProps,
	mapDispatchToProps
)(HeaderInner);

export default Header;