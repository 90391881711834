import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { Div } from 'glamorous';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import {Form, Formik} from 'formik';
import FormFieldError from '../../../../components/FormFieldError/FormFieldError';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import constants from '../../../../utils/constants';
import UniField from  '../../../Wizard/components/UniFields/UniField';

export interface IResetPasswordRequestFormProps {
    forgotPasswordRequest: Function;
    lang: string;
}

interface IResetPasswordRequestFormValues {
    email: string;
}

export class ResetPasswordRequestForm extends React.Component<WithNamespaces & IResetPasswordRequestFormProps> {

    handleSubmit = (values: IResetPasswordRequestFormValues, form: Formik) => {
        console.log(this);
        return this.props.forgotPasswordRequest(values.email, this.props.lang)
            .then(
                (action: any) => {
                    if (action && action.error) {
                        console.log(action);
                        form.setErrors({
                            'email': action.error[0]
                        });
                        form.setSubmitting(false);
                    }
                }
            );
    };

    createValidationSchema() {
        return yup.object().shape({
            email: yup.string()
                .email('global.email')
                .required('global.notBlank'),
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Div>
                <h3>{t('forgotPassword.title.label')}</h3>
                <p>{t('forgotPassword.title.placeholder')}</p>

                <Formik
                    initialValues={{email: ''}}
                    onSubmit={this.handleSubmit.bind(this)}
                    isInitialValid={true}
                    validationSchema={this.createValidationSchema()}
                >
                    {props => {
                        return (
                            <Div>
                                <Form>
                                    <UniField name="email" textKey="forgotPassword.email" />
                                    <Button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        {t('forgotPassword.button.label')}
                                    </Button>
                                </Form>
                                <p>
                                    {t('forgotPassword.login.label')}{' '}
                                    <Link to={constants.ROUTES.AUTH.LOGIN}>
                                        {t('forgotPassword.login.placeholder')}
                                    </Link>
                                </p>
                            </Div>
                        )
                    }}
                </Formik>
            </Div>
        )
    }
}

export default translate('translations')(ResetPasswordRequestForm);