import { Field, FieldProps, getIn } from 'formik';
import { FormGroup, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import HelpIcon from "../../../../../components/HelpIcon/HelpIcon";
import glamorous, {Div} from 'glamorous';
import theme from "../../../../../styles/theme";
import FormFieldError from "../../../../../components/FormFieldError/FormFieldError";
import {ITaxReturn} from "../../../../Dashboard/components/TaxReturn/ITaxReturn";

interface ITaxNumberFieldProps {
    name: string;
    fieldName: string;
    textKey?: string;
    required?: boolean;
    taxReturn: ITaxReturn;
}

class TaxNumberFieldInner extends React.Component<WithNamespaces & FieldProps & ITaxNumberFieldProps> {
    render () {
        const {
            field,
            textKey,
            required,
            t,
            form: { errors, touched },
            taxReturn
        } = this.props;
        const _textKey = textKey ? textKey : field.name;

        const hasHelp = t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''}) !== '';

        return (<Field>
            {({ form }) => (<Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t(_textKey + '.label')}{ required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon taxReturn={taxReturn} helpKey={_textKey} t={t} />}
                    </Label>
                    <TaxNumberInputDiv>
                        <CzLabel>CZ</CzLabel>
                        <Div>
                            <Input
                                type="text"
                                {...field}
                                placeholder={t(_textKey + '.placeholder', {defaultValue: ''})}
                            />
                        </Div>
                    </TaxNumberInputDiv>
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={_textKey} isValidating={form.isValidating} t={t}/>
            </Div>)}
        </Field>);
    }
}

const TaxNumberField: React.SFC<WithNamespaces & ITaxNumberFieldProps> = ({ name, ...rest }) => {
    return (
        <Field name={name} {...rest} component={TaxNumberFieldInner} />
    )
};

const TaxNumberInputDiv = glamorous(Div)({
    display: 'flex',
    alignItems: 'center',
});

const CzLabel = glamorous(Div)({
    color: theme.colors.grayDark,
    marginRight: '4px',
});

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {};
};

export default translate('translations')(connect(
	mapStateToProps,
	mapDispatchToProps
)(TaxNumberField)) as any;
