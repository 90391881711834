import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import usersActions from '../../actions/usersActions';
import ConfirmUser from './ConfirmUser';

export interface IConfirmUserContainerProps {
	confirm: Function;
	lang: string;
}

class ConfirmUserContainer extends React.Component<IConfirmUserContainerProps & RouteComponentProps> {
	componentDidMount() {
		const queryParams = queryString.parse(this.props.location.search);

		if (queryParams.token) {
			this.props.confirm(queryParams.token, this.props.lang);
		}
	}

	render() {
		return (
			<ConfirmUser/>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
        lang: state.app.lang.lang,
	};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {
		confirm: (token:string, language: string) => {
			return dispatch(usersActions.confirm(token, language));
		},
	};
};

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(ConfirmUserContainer));