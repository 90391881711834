import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import SavingIndicatorContainer from '../../components/SavingIndicator/SavingIndicatorContainer';
import StepHeadline from './components/StepHeadline/StepHeadline';
import StepZeroFormContainer from './components/StepZero/StepZeroFormContainer';
import StepOneFormContainer from './components/StepOne/StepOneFormContainer';
import StepTwoFormContainer from './components/StepTwo/StepTwoFormContainer';
import StepThreeFormContainer from './components/StepThree/StepThreeFormContainer';
import StepFourFormContainer from './components/StepFour/StepFourFormContainer';
import StepFiveFormContainer from './components/StepFive/StepFiveFormContainer';
import AppLayout from "../../layouts/AppLayout";
import AdditionalServicesFormContainer from "./components/AdditionalServices/AdditionalServicesFormContainer";

interface IStepZeroPageProps {
	step: number;
}

class WizardPage extends React.Component<WithNamespaces & IStepZeroPageProps & RouteComponentProps, any> {
	render() {
		const { t, step } = this.props;
		return (
			<AppLayout>
                <StepHeadline />
				<SavingIndicatorContainer />
                <Switch>
                    <PrivateRoute exact path="/wizard/create" component={StepZeroFormContainer} />
                    <PrivateRoute exact path="/wizard/step-one/:trId" component={StepOneFormContainer} />
                    <PrivateRoute exact path="/wizard/step-two/:trId" component={StepTwoFormContainer} />
                    <PrivateRoute exact path="/wizard/step-three/:trId" component={StepThreeFormContainer} />
                    <PrivateRoute exact path="/wizard/step-four/:trId" component={StepFourFormContainer} />
                    <PrivateRoute exact path="/wizard/step-five/:trId" component={StepFiveFormContainer} />
                    <PrivateRoute exact path="/wizard/additional-services/:trId" component={AdditionalServicesFormContainer} />
                </Switch>
			</AppLayout>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		step: state.app.wizard.step
	};
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {
	};
};

export default translate('translations')(withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(WizardPage)) as any);
