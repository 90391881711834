import { Field, getIn } from 'formik';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import HelpIcon from "../../../../../components/HelpIcon/HelpIcon";
import {Div} from 'glamorous';
import FormFieldError from "../../../../../components/FormFieldError/FormFieldError";

export default function SideActivityReason(props) {
    const { name, value, t, required } = props;
    const hasHelp = t(name + '.helpText', {defaultValue: ''}) !== ''
        || t(name + '.helpImage', {defaultValue: ''}) ;

    return (<Field name={name} value={value}>
        {({ field, form }) => (
            <Div className={!form.isValidating && getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t('healthInsurance.healthInsuranceOffice.label')}{required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon helpKey={name} t={t} />}
                    </Label>
                    <select {...field} className={field.value ? null : 'empty'}>
                        <option value="">{t('healthInsurance.healthInsuranceOffice.placeholder')}</option>
                        <option value="HI111">{t('healthInsurance.healthInsuranceOffices.HI111.label')}</option>
                        <option value="HI201">{t('healthInsurance.healthInsuranceOffices.HI201.label')}</option>
                        <option value="HI205">{t('healthInsurance.healthInsuranceOffices.HI205.label')}</option>
                        <option value="HI207">{t('healthInsurance.healthInsuranceOffices.HI207.label')}</option>
                        <option value="HI209">{t('healthInsurance.healthInsuranceOffices.HI209.label')}</option>
                        <option value="HI211">{t('healthInsurance.healthInsuranceOffices.HI211.label')}</option>
                        <option value="HI213">{t('healthInsurance.healthInsuranceOffices.HI213.label')}</option>
                    </select>
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={name} isValidating={form.isValidating} t={t}/>
            </Div>)}
    </Field>);
}