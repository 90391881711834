import React from 'react';
import PropTypes from 'prop-types';
import { translate, WithNamespaces } from 'react-i18next';
import glamorous, { Div } from 'glamorous';
import { Button, FormGroup, Label } from 'reactstrap';
import {Form, Formik, Field, ErrorMessage} from 'formik';
import FormFieldError from '../../../../components/FormFieldError/FormFieldError';
import { Link } from 'react-router-dom';
import { appConfig } from '../../../../config/appConfig';
import constants from '../../../../utils/constants';
import UniField from  '../../../Wizard/components/UniFields/UniField';
import * as yup from 'yup';

export interface ISignUpFormProps {
	signUp: Function;
	lang: string;
}

interface ISignUpFormValues {
	email: string;
	firstName: string;
    lastName: string;
	termsOfService: boolean;
	personalDataProcessing: boolean;
	isSubscribedToMailNotifications: boolean;
	password: string;
	passwordConfirm: string;
}

export class SignUpForm extends React.Component<WithNamespaces & ISignUpFormProps> {

	handleSubmit = (values: ISignUpFormValues, form: any) => {
		this.props
			.signUp(
				values.email,
				values.firstName,
                values.lastName,
				values.password,
				values.termsOfService,
				values.personalDataProcessing,
				values.isSubscribedToMailNotifications,
				this.props.lang
			)
            .then(
                (action: any) => {
                    if (action && action.error) {
                        form.setErrors(action.error);
                        form.setSubmitting(false);
                    }
                }
            );
	};

	createValidationSchema() {
		return yup.object().shape({
			email: yup.string()
				.email('global.email')
				.required('global.notBlank'),
			firstName: yup.string()
				.required('global.notBlank'),
            lastName: yup.string()
                .required('global.notBlank'),
			password: yup.string()
				.required('global.notBlank')
				.min(8, 'minLength'),
			passwordConfirm: yup.string()
				.oneOf([yup.ref('password')], 'passwordsDoNotMatch')
				.required('global.notBlank'),
			termsOfService: yup.mixed()
				.oneOf([true], 'global.checked'),
			personalDataProcessing: yup.mixed()
				.oneOf([true], 'global.checked'),
            isSubscribedToMailNotifications: yup.boolean(),
		});
	}

	render() {
		const { lang, t } = this.props;

		return (
			<Div>
                <h3>{t('signUp.title.label')}</h3>
				<p>{t('signUp.title.placeholder')}</p>

                <Formik
                    initialValues={{email:'', firstName:'', lastName:'', password:'', passwordConfirm:'', termsOfService:false, personalDataProcessing: false, isSubscribedToMailNotifications: false}}
                    onSubmit={this.handleSubmit}
                    isInitialValid={true}
                    validationSchema={this.createValidationSchema()}
                    validateOnChange={false}
					validateOnBlur={true}
                >
                    {props => {
                        return (
                            <Div>
                                <Form>
                                    <UniField name="email" textKey="signUp.email" />
                                    <UniField name="firstName" textKey="signUp.firstName" />
                                    <UniField name="lastName" textKey="signUp.lastName" />
                                    <UniField name="password" type="password" textKey="signUp.password" errorByName={true} />
                                    <UniField name="passwordConfirm" type="password" textKey="signUp.passwordConfirm" errorByName={true} />
									<FormGroup className="checkbox">
										<Field
											type="checkbox"
											name="termsOfService"
											id="termsOfService"
										/>
										<Label for="termsOfService">
											{t('signUp.termsOfService.label')}{' '}
											<a href={appConfig.webBaseUrl + constants.WEB[lang.toUpperCase()].TERMS_OF_SERVICE}
											   target="_blank">
												{t('signUp.termsOfService.placeholder')}
											</a>
										</Label>
                                        <ErrorMessage
                                            name="termsOfService"
                                            render={msg => <div className="errorMessage">{t(msg.includes(' ') ? `validations.global.invalid` : (msg.startsWith('global') ? `validations.${msg}.label` : `validations.termsOfService.${msg}.label`))}</div>}
                                        />
                                    </FormGroup>
									<FormGroup className="checkbox">
										<Field
											type="checkbox"
											name="personalDataProcessing"
                                            id="personalDataProcessing"
										/>
										<Label for="personalDataProcessing">
											{t('signUp.personalDataProcessing.label')}{' '}
											<a href={appConfig.webBaseUrl + constants.WEB[lang.toUpperCase()].PERSONAL_DATA_PROCESSING}
												target="_blank">
												{t('signUp.personalDataProcessing.placeholder')}
											</a>
										</Label>
                                        <ErrorMessage
                                            name="personalDataProcessing"
                                            render={msg => <div className="errorMessage">{t(msg.includes(' ') ? `validations.global.invalid` : (msg.startsWith('global') ? `validations.${msg}.label` : `validations.personalDataProcessing.${msg}.label`))}</div>}
                                        />
                                    </FormGroup>
                                    <FormGroup className="checkbox">
                                        <Field
                                            type="checkbox"
                                            name="isSubscribedToMailNotifications"
                                            id="isSubscribedToMailNotifications"
                                        />
                                        <Label for="isSubscribedToMailNotifications">
                                            {t('signUp.isSubscribedToMailNotifications.label')}{' '}
                                        </Label>
                                    </FormGroup>
                                    <Button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        {t('signUp.button.label')}
                                    </Button>
                                </Form>
								<p>
									{t('signUp.alreadyRegistered.label')}{' '}
									<Link to={constants.ROUTES.AUTH.LOGIN}>
										{t('signUp.alreadyRegistered.placeholder')}
									</Link>
								</p>
                            </Div>
                        )
                    }}
                </Formik>
			</Div>
		)
	}
}

export default translate('translations')(SignUpForm);