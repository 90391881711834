import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import constants from '../../../../utils/constants';
import history from '../../../../utils/history';
import { IStepContainerProps } from '../../interfaces/IStepContainerProps';
import { mapDispatchToProps, mapStateToProps } from '../wizardStepPropsMappers';
import StepFiveForm from './StepFiveForm';
import {trActions} from "../../../Common/actions/trActions";

interface IStepFiveContainerProps {
  certifiedCheckAttachments: any;
  loadCertifiedCheckAttachments: Function;
  attachFile: Function;
  deleteAttachment: Function;
}

const stepFiveMapStateToProps = (state: any, props: any) => {
  const stepProps = mapStateToProps(state, props);
  return  {
    ...stepProps,
    certifiedCheckAttachments: state.entities.certifiedCheckAttachments,
    onMyBehalfAttachments: state.entities.onMyBehalfAttachments,
  }
};

const stepFiveMapDispatchToProps = (dispatch: Function, props: any) => {
  const stepProps = mapDispatchToProps(dispatch, props);
  return  {
    ...stepProps,
    loadCertifiedCheckAttachments: (id: string) => {
      return dispatch(trActions.certifiedCheckAttachments(id));
    },
    loadOnMyBehalfAttachments: (id: string) => {
        return dispatch(trActions.onMyBehalfAttachments(id));
    },
    attachFile: (id: string, type: string, file: File) => {
      return dispatch(trActions.attachFile(id, type, file, 'stepFive'));
    },
    deleteAttachment: (taxReturnId: string, attachmentId: string) => {
      return dispatch(trActions.deleteAttachment(taxReturnId, attachmentId, 'stepFive'));
    },
  }
};

class StepFiveFormContainer extends React.Component<WithNamespaces & IStepContainerProps & IStepFiveContainerProps & RouteComponentProps> {

	componentWillMount() {
		const queryParams = this.props.match.params;
		if (queryParams.trId) {
			this.props.load(queryParams.trId);
		} else {
			history.replace(constants.ROUTES.WIZARD.STEP[0]);
		}
	}

	componentDidMount() {
		this.props.setStep(5);
	}

	render() {
		return (
			<StepFiveForm {...this.props} />
		)
	}
}

export default translate('translations')(withRouter(connect(
	stepFiveMapStateToProps,
	stepFiveMapDispatchToProps
)(StepFiveFormContainer)) as any);