import React from 'react';
import { Container } from 'reactstrap';
import glamorous, { Div, Img } from 'glamorous';
import theme from '../styles/theme';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import appConfig from "../config/appConfig";

export const SignUpLayout = ({children}) => (
    <SignUpOverlay>
        <SignUpTitle>
            <a href={appConfig.webBaseUrl}><TitleImg src={'/logo-white.svg'} /></a>
            <LanguageSwitcher />
        </SignUpTitle>
        <SignUpFormBlock>
            {children}
        </SignUpFormBlock>
    </SignUpOverlay>
);

const SignUpOverlay = glamorous(Div)({
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.blueGradient,
    color: theme.colors.white,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [':after']: {
        flexGrow: 1,
        content: '""',
    }
});

const TitleImg = glamorous(Img)({
    height: '50px',
    ['@media (max-width: 490px)']: {
        height: '40px',
    },
    ['@media (max-width: 400px)']: {
        height: '32px',
    },
    ['@media (max-width: 360px)']: {
        height: '24px',
    },
});

const SignUpBlock = glamorous(Div)({
    alignSelf: 'center',
    color: theme.colors.black,
    maxWidth: '490px',
    width: '100%',
});

const SignUpTitle = glamorous(Div)({
    fontSize: '24px',
    flexGrow: 1,
    flexBasis: 1,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '490px',
    width: '100%',
    minHeight: '125px',
    paddingLeft: '12px',
    paddingRight: '12px',
});

const SignUpFormBlock = glamorous(Div)({
    background: theme.colors.white,
    color: theme.colors.black,
    fontSize: '12px',
    padding: '36px 48px',
    maxWidth: '490px',
    textAlign: 'center',
    width: '100%',
    ['@media (max-width: 490px)']: {
        padding: '24px',
    }
});

export default SignUpLayout;
