import createReducer from '../../store/createReducer';
import trConstants from "../../modules/Common/actions/trConstants";

function loadSuccess(state, action) {
	return action.result;
}

function loadFailure(state, action) {
	const { result } = action;
	return {};
}

export const certifiedCheckAttachments = createReducer({}, {
  [trConstants.CERTIFIED_CHECK_ATTACHMENTS.SUCCESS]: loadSuccess,
  [trConstants.CERTIFIED_CHECK_ATTACHMENTS.FAILURE]: loadFailure,
	[trConstants.CERTIFIED_CHECK_ATTACHMENTS.REQUEST]: (state, action) => {
		return {};
    },
});