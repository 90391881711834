import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import constants from "../../../../utils/constants";

export interface IStepHeadlineProps {
    step: number;
    numberOfSteps: number;
    taxReturnId: string;
}

class StepHeadlineInner extends React.Component<WithNamespaces & IStepHeadlineProps> {

	render() {
        const { t, step, numberOfSteps, taxReturnId } = this.props;

        if (step === 0) {
            return null;
        }

        return (
            <Nav className="steps">
                {(new Array(numberOfSteps).fill(1)).map((item, index) => {
                    const currentStep = index + 1;
                    return <NavItem key={currentStep} className={currentStep === step ? 'active' : ''}>
                        <NavLink
                             to={constants.ROUTES.WIZARD.STEP[currentStep] + '/' + this.props.taxReturnId}>
                            {t(`step${currentStep}.label`)}
                        </NavLink>
                    </NavItem>
                })}
            </Nav>
		)
	}
}

export default translate('translations')(StepHeadlineInner);
