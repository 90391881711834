import appConfig from "../../../config/appConfig";

interface GoPayApi {
    checkout(options: {gatewayUrl: string, inline?: boolean}, callback: Function);
}

interface CheckoutResult {
    id: string;
    url: string;
}

declare global {
    interface Window {
        _gopay?: GoPayApi;
    }
}

let goPayApiPromise;

export function loadGoPayApi(): Promise<GoPayApi> {
    if (!goPayApiPromise) {
        goPayApiPromise = new Promise((resolve, reject) => {
            if (window._gopay) {
                return resolve(window._gopay);
            }

            const script = document.createElement('script');
            script.src = appConfig.goPayEmbedUrl;
            script.onerror = reject;
            script.onload = () => {
                resolve(window._gopay!)
            };

            document.head.appendChild(script);
        });
    }

    return goPayApiPromise;
}

export async function checkout(gatewayUrl: string, inline: boolean = true): Promise<CheckoutResult> {
    const goPayApi = await loadGoPayApi();

    return new Promise<CheckoutResult>((resolve) => {
        goPayApi.checkout({gatewayUrl, inline: true}, resolve);
    });
}
