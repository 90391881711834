import { connect } from 'react-redux';
import { langActions } from '../langs/langActions';
import { langService } from '../services/langService';
import LanguageSwitcherInner from './LanguageSwitcherInner';

const mapStateToProps = (state) => {
	return {
		allLanguages: ['en', 'cs', 'de', 'it', 'pl', 'ru', 'sk', 'uk', 'vi'],
		currentLanguage: state.app.lang.lang
	};
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {
		loadTranslations: (language: string) => {
			langService.save(language);
			return dispatch(langActions.load(language));
		}
	};
};

export const LanguageSwitcher = connect(
	mapStateToProps,
	mapDispatchToProps
)(LanguageSwitcherInner);

export default LanguageSwitcher;
