import React from 'react';
import { connect } from 'react-redux';
import ResetPasswordFormContainer from './components/ResetPasswordForm/ResetPasswordFormContainer';
import SignUpLayout from "../../layouts/SignUpLayout";

class ResetPasswordPage extends React.Component<any, any> {
	render() {
		return (
			<SignUpLayout>
				<ResetPasswordFormContainer />
			</SignUpLayout>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {

	};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {

	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ResetPasswordPage);