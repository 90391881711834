import React from 'react';
import { Container, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import glamorous, { Div } from 'glamorous';
import theme from '../styles/theme';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { Header } from "../components/Header/Header";

export const AppLayout = ({children}) => (
    <Div>
        <Header />
        <Div className="appContent">
            {children}
        </Div>
    </Div>
);

export default AppLayout;
