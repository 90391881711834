import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import constants from '../../../../utils/constants';
import history from '../../../../utils/history';
import { IStepContainerProps } from '../../interfaces/IStepContainerProps';
import { mapDispatchToProps, mapStateToProps } from '../wizardStepPropsMappers';
import StepTwoForm from './StepTwoForm';
import queryString from 'query-string';

class StepTwoFormContainer extends React.Component<WithNamespaces & IStepContainerProps & RouteComponentProps> {

	componentWillMount() {
		const queryParams = this.props.match.params;
		if (queryParams.trId) {
			this.props.load(queryParams.trId);
		} else {
			history.replace(constants.ROUTES.WIZARD.STEP[0]);
		}
	}

	componentDidMount() {
		this.props.setStep(2);
	}

	render() {
        const query = queryString.parse(this.props.location.search);
        const shouldValidate = !!query.shouldValidate;

		return (
			<StepTwoForm {...this.props} shouldValidate={shouldValidate} />
		)
	}
}

export default translate('translations')(withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(StepTwoFormContainer)) as any);