import { getApiConstants } from "../../../utils/apiConstants";

export const usersConstants = {
	SIGN_UP: getApiConstants('SIGN_UP'),
	CONFIRM: getApiConstants('CONFIRM'),
	LOGIN: getApiConstants('LOGIN'),
	RESET_PASSWORD_REQUEST: getApiConstants('RESET_PASSWORD_REQUEST'),
	RESET_PASSWORD: getApiConstants('RESET_PASSWORD'),
	GET_USERS: getApiConstants('GET_USERS'),
	LOAD_USER: getApiConstants('LOAD_USER'),
	LOGOUT: getApiConstants('USER_LOGOUT'),
};

export default usersConstants;