import { getI18n } from 'react-i18next';
import apiService from './apiService';
import langConstants from '../langs/langConstants';
import { API } from '../utils/apiConstants';

const i18n = getI18n();

export const langService = {
    load,
    save,
    getSavedLang
};

function load(lang: string) {
    return apiService.get(`${API.LANGUAGES}/${lang}`)
        .catch(() => {
            return true;
        });
}

function save(lang: string): void {
    localStorage.setItem(langConstants.LANG_LOCAL_STORAGE_KEY, lang);
}

function getSavedLang(): string {
    return localStorage.getItem(langConstants.LANG_LOCAL_STORAGE_KEY);
}