import langConstants from '../../langs/langConstants';
import createReducer from '../../store/createReducer';
import { langService } from '../../services/langService';

const initialState = {
	lang: langService.getSavedLang() || langConstants.DEFAULT_LANGUAGE
}

const langReducer = createReducer(initialState, {
	[langConstants.LOAD.SUCCESS]: langChanged
});

function langChanged(state, action) {
	return {
		lang: action.result.lang
	};
}

export default langReducer;