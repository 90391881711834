import React from 'react';
import glamorous,{Div} from 'glamorous';
import {Col, Row, Container} from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import constants from '../../../../../utils/constants';
import CatalogLabel from '../../../../../components/CatalogComboField/CatalogLabel';

interface IPersonalDataSummaryProps {
    personalData: any;
    taxReturnId: string;
    t: Function;
}

export default class PersonalDataSummary extends React.Component<IPersonalDataSummaryProps> {
	render() {
        const { personalData, taxReturnId, t } = this.props;

        return (<Div className="formSection summary">
            <h3 className="noCheckbox">{t('sections.personalData.label')}</h3>
            <Div className="subForm">
                <Row>
                    <Col sm="6">
                        {t('personalData.firstName.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{personalData.firstName}</strong>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        {t('personalData.lastName.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{personalData.lastName}</strong>
                    </Col>
                </Row>
                {personalData.title && <Row>
                    <Col sm="6">
                        {t('personalData.title.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{personalData.title}</strong>
                    </Col>
                </Row>}
                {personalData.birthLastName && <Row>
                    <Col sm="6">
                        {t('personalData.birthLastName.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{personalData.birthLastName}</strong>
                    </Col>
                </Row>}
                {personalData.phone && <Row>
                    <Col sm="6">
                        {t('personalData.phone.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{personalData.phone}</strong>
                    </Col>
                </Row>}
                <Row>
                    <Col sm="6">
                        {t('personalData.email.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{personalData.email}</strong>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        {t('personalData.nationality.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{personalData.nationality}</strong>
                    </Col>
                </Row>
                <Link to={constants.ROUTES.WIZARD.STEP[2] + '/' + taxReturnId}>{t('editThisSection.label')}</Link>
            </Div>
        </Div>)
	}
}
