import React from 'react';
import { connect } from 'react-redux';
import ConfirmUserContainer from './components/ConfirmUser/ConfirmUserContainer';
import SignUpLayout from '../../layouts/SignUpLayout';

class ConfirmUserPage extends React.Component<any, any> {
	render() {
		return (
			<SignUpLayout>
                <ConfirmUserContainer />
			</SignUpLayout>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {

	};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {

	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ConfirmUserPage);