import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Div } from 'glamorous';
import React, { Fragment } from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import theme from '../../styles/theme';

interface ISignUpFormProps {
	zIndex: number;
    isSaving: boolean
}

class SavingIndicatorInner extends React.Component<WithNamespaces & ISignUpFormProps> {
	render() {
		const { t, zIndex, isSaving } = this.props;
		let settings = {
			backgroundColor: theme.colors.green,
			borderColor: theme.colors.white,
			color: theme.colors.white,
			icon: faCheck,
			text: t('saved.label')
		};
		if (isSaving) {
			settings = {
				backgroundColor: theme.colors.orange,
				borderColor: theme.colors.red,
				color: theme.colors.black,
				icon: faSpinner,
				text: t('saving.label')
			};
		}
		return (
			<Div
				float="right"
				width="180px"
				margin="5px 10px"
				zIndex={zIndex}
			>
				<Div
					padding="8px 12px"
					backgroundColor={settings.backgroundColor}
					borderColor={settings.borderColor}
				>
					{!isSaving && <Fragment><FontAwesomeIcon icon={settings.icon} style={{color: settings.color}} /> {settings.text}...</Fragment> }
					{isSaving && <Fragment><FontAwesomeIcon icon={settings.icon} spin style={{color: settings.color}} /> {settings.text}...</Fragment> }
				</Div>
			</Div>
		)
	}
};

export default translate('translations')(SavingIndicatorInner);