import {faHome, faArrowCircleLeft, faWallet, faDownload, faSpinner} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import glamorous,{Div} from 'glamorous';
import { get } from 'lodash';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import {Button, Col, Row} from 'reactstrap';
import { IStepProps } from '../../interfaces/IStepProps';
import { WizardService } from '../../services/WizardService';
import * as paymentService from '../../services/paymentService';
import constants from "../../../../utils/constants";
import history from '../../../../utils/history';
import UniCheckbox from '../UniFields/UniCheckbox';
import UniField from '../UniFields/UniField';
import { OTHER_INCOME_TYPES } from '../StepOne/FormFields/OtherIncomeType';
import apiConfig from "../../../../config/apiConfig";
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Attachments from "../../../../components/Attachments/Attachments";
import ValidationWrapper from "../../../../components/ValidationWrapper/ValidationWrapper";
import axios from 'axios';
import fileDownload from 'js-file-download';
import theme from '../../../../styles/theme';

interface IAdditionalServicesProps {
    certifiedCheckAttachments: any;
    loadCertifiedCheckAttachments: Function;
    onMyBehalfAttachments: any;
    loadOnMyBehalfAttachments: Function;
    attachFile: Function;
    deleteAttachment: Function;
}

interface IAdditionalServicesState {
    certifiedCheckAttachmentsLoaded: boolean;
    onMyBehalfAttachmentsLoaded: boolean;
    poaDownloading: boolean;
    nonWorkingSpouseDeclarationDownloading: boolean;
    secondParentDeclarationDownloading: boolean;
}

class AdditionalServicesForm extends React.Component<WithNamespaces & IStepProps & IAdditionalServicesProps, IAdditionalServicesState> {

    readonly state = {
        certifiedCheckAttachmentsLoaded: false,
        onMyBehalfAttachmentsLoaded: false,
        poaDownloading: false,
        nonWorkingSpouseDeclarationDownloading: false,
        secondParentDeclarationDownloading: false,
    };

    constructor(props) {
        super(props);
        this.goPay = this.goPay.bind(this);
    }

    componentDidMount() {
        paymentService.loadGoPayApi();
    }

    componentDidUpdate() {
        const { taxReturn, loadCertifiedCheckAttachments, loadOnMyBehalfAttachments } = this.props;

        if (taxReturn.certifiedCheckAdditionalService && !this.state.certifiedCheckAttachmentsLoaded) {
            loadCertifiedCheckAttachments(taxReturn.id);
            this.setState((oldState) => ({
                certifiedCheckAttachmentsLoaded: true,
            }));
        }

        if (taxReturn.onMyBehalfAdditionalService && !this.state.onMyBehalfAttachmentsLoaded) {
            loadOnMyBehalfAttachments(taxReturn.id);
            this.setState((oldState) => ({
                onMyBehalfAttachmentsLoaded: true,
            }));
        }

    };

    goToDownload = () => {
        history.push('/download/' + this.props.taxReturn.id);
    };

    downloadPoa = () => {
        if (this.state.poaDownloading) {
            return;
        }

        this.setState((oldState) => ({
            ...oldState,
            poaDownloading: true,
        }));
        axios.get(
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/power-of-attorney-pdf`,
            { responseType: 'blob', withCredentials: true }
        ).then((response) => {
            this.setState((oldState) => ({
                ...oldState,
                poaDownloading: false
            }));
            fileDownload(response.data, this.props.t(`download.poa.label`) + '.pdf');
        });
    };

    downloadNonWorkingSpouseDeclaration = () => {
        if (this.state.nonWorkingSpouseDeclarationDownloading) {
            return;
        }

        this.setState((oldState) => ({
            ...oldState,
            nonWorkingSpouseDeclarationDownloading: true,
        }));
        axios.get(
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/non-working-spouse-declaration-pdf`,
            { responseType: 'blob', withCredentials: true }
        ).then((response) => {
            this.setState((oldState) => ({
                ...oldState,
                nonWorkingSpouseDeclarationDownloading: false
            }));
            fileDownload(response.data, this.props.t(`download.non_working_spouse_declaration.label`) + '.pdf');
        });
    };

    downloadSecondParentDeclaration = () => {
        if (this.state.secondParentDeclarationDownloading) {
            return;
        }

        this.setState((oldState) => ({
            ...oldState,
            secondParentDeclarationDownloading: true,
        }));
        axios.get(
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/second-parent-declaration-pdf`,
            { responseType: 'blob', withCredentials: true }
        ).then((response) => {
            this.setState((oldState) => ({
                ...oldState,
                secondParentDeclarationDownloading: false
            }));
            fileDownload(response.data, this.props.t(`download.second_parent_declaration.label`) + '.pdf');
        });
    };

    render() {
        const { t, taxReturn, lang, update, validate, shouldValidate,
            certifiedCheckAttachments, onMyBehalfAttachments, attachFile, deleteAttachment } = this.props;

        const thousandSeparator = lang === 'cs' ? ' ' : ',';
        const czkSuffix = lang === 'cs' ? ' Kč' : ' CZK';

        if (!taxReturn.year) {
            return null;
        }

        const wizardService = new WizardService(6, taxReturn.id, update, validate, t);

        return (<Formik
            initialValues={taxReturn}
            onSubmit={values => console.log(values)}
            validateOnChange={false}
            validate={wizardService.validateAndSave}
        >
            {({values, validateForm, setFieldTouched}) => (<Div>
                <Div className="navButtons">
                    <Button title={t('buttons.backToDashboard.label')} onClick={() => wizardService.backToDashboard()}>
                        <FontAwesomeIcon icon={faHome} />
                    </Button>
                    <Button title={t('buttons.pay.label')}className="btn-primary" onClick={() => wizardService.goPay(validateForm, setFieldTouched, t, this.goPay)}>
                        <FontAwesomeIcon  icon={faWallet} />
                    </Button>
                    <Button title={t('buttons.download.label')} className="btn-primary" onClick={this.goToDownload}>
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>
                </Div>
                <h1>{taxReturn.personalData.firstName} {taxReturn.personalData.lastName} {taxReturn.year}</h1>

                <Form>
                    {shouldValidate && <ValidationWrapper wizardService={wizardService} validateForm={validateForm} setFieldTouched={setFieldTouched}/>}
                    <Div className="additionalServices">
                        <h3>{t('additionalService.title.label')}</h3>
                        {!taxReturn.certifiedCheckRequired && <Div className="formSection">
                            <h3>
                                <UniCheckbox
                                    name="certifiedCheckAdditionalService"
                                    textKey="certifiedCheckRequired"
                                    validateForm={validateForm}
                                    placeholder={true}
                                    taxReturn={taxReturn}
                                />
                            </h3>
                            {values.certifiedCheckAdditionalService && <Div className="subForm">
                                <Row>
                                    <Col xs="12" sm="6">
                                        <UniField name="certifiedCheckNote" taxReturn={taxReturn} />
                                    </Col>
                                </Row>
                                {certifiedCheckAttachments && Object.keys(certifiedCheckAttachments).length > 0 && <Div>
                                    <p><strong>{t('download.certifiedCheckAttachments.title.label')}</strong></p>
                                    <p>{t('download.certifiedCheckAttachments.title.placeholder')}</p>
                                    <Attachments
                                        attachments={certifiedCheckAttachments}
                                        taxReturn={taxReturn}
                                        attachFile={attachFile}
                                        deleteAttachment={deleteAttachment}
                                        t={t}
                                    />
                                </Div>}
                            </Div>}
                        </Div>}

                        {!taxReturn.printingRequired && <Div className="formSection">
                            <h3>
                                <UniCheckbox
                                    name="printingAdditionalService"
                                    textKey="printingRequired"
                                    validateForm={validateForm}
                                    placeholder={true}
                                    taxReturn={taxReturn}
                                />
                            </h3>
                            {values.printingAdditionalService && <Div className="subForm">
                                <p><strong>{t('pleaseDeliver.label')}</strong></p>
                                <Row>
                                    <Col xs="12" sm="6">
                                        <UniField name="printingInfo.name" required={true} taxReturn={taxReturn} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6">
                                        <UniField name="printingInfo.address.street" textKey="address.street" required={true} taxReturn={taxReturn} />
                                    </Col>
                                    <Col xs="12" sm="6">
                                        <UniField name="printingInfo.address.number" textKey="address.number" taxReturn={taxReturn} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6">
                                        <UniField name="printingInfo.address.city" textKey="address.city" required={true} taxReturn={taxReturn} />
                                    </Col>
                                    <Col xs="12" sm="6">
                                        <UniField name="printingInfo.address.zipCode" textKey="address.zipCode" taxReturn={taxReturn} />
                                    </Col>
                                </Row>
                            </Div>}
                        </Div>}

                        {!taxReturn.onMyBehalfRequired && <Div className="formSection">
                            <h3>
                                <UniCheckbox
                                    name="onMyBehalfAdditionalService"
                                    textKey="onMyBehalfRequired"
                                    validateForm={validateForm}
                                    placeholder={true}
                                    taxReturn={taxReturn}
                                />
                            </h3>
                            {values.onMyBehalfAdditionalService && <Div className="subForm">
                                <p>{t('download.poa.placeholder')}</p>
                                <StyledButton className="btn-primary download" disabled={this.state.poaDownloading} title={t('download.poa.label')} onClick={this.downloadPoa}>
                                    {this.state.poaDownloading && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                    {!this.state.poaDownloading && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                    {' '}{t('download.poa.label')}
                                </StyledButton>

                                {taxReturn.sections.married && taxReturn.sections.commonHousehold && taxReturn.sections.partners && <Div>
                                    <p>{t('download.non_working_spouse_declaration.placeholder')}</p>
                                    <StyledButton className="btn-primary download" disabled={this.state.nonWorkingSpouseDeclarationDownloading} title={t('download.non_working_spouse_declaration.label')} onClick={this.downloadNonWorkingSpouseDeclaration}>
                                        {this.state.nonWorkingSpouseDeclarationDownloading && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                        {!this.state.nonWorkingSpouseDeclarationDownloading && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                        {' '}{t('download.non_working_spouse_declaration.label')}
                                    </StyledButton>
                                </Div>}

                                {taxReturn.sections.children && taxReturn.sections.secondParentClaim && taxReturn.deductibles.children.length > 0 && <Div>
                                    <p>{t('download.second_parent_declaration.placeholder')}</p>
                                    <StyledButton className="btn-primary download" disabled={this.state.secondParentDeclarationDownloading} title={t('download.second_parent_declaration.label')} onClick={this.downloadSecondParentDeclaration}>
                                        {this.state.secondParentDeclarationDownloading && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                        {!this.state.secondParentDeclarationDownloading && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                        {' '}{t('download.second_parent_declaration.label')}
                                    </StyledButton>
                                </Div>}

                                {onMyBehalfAttachments && Object.keys(onMyBehalfAttachments).length > 0 && <Div>
                                    <p><strong>{t('download.onMyBehalfAttachments.title.label')}</strong></p>
                                    <p>{t('download.onMyBehalfAttachments.title.placeholder')}</p>
                                    <Attachments
                                        attachments={onMyBehalfAttachments}
                                        taxReturn={taxReturn}
                                        attachFile={attachFile}
                                        deleteAttachment={deleteAttachment}
                                        t={t}
                                    />
                                </Div>}

                                {/*{taxReturn.year === 2021 && <Div>*/}
                                {/*    <p><strong>{t('download.xmlWarning.label')}</strong></p>*/}
                                {/*    <p>{t('download.xmlWarning.placeholder')}</p>*/}
                                {/*</Div>}*/}
                            </Div>}
                        </Div>}
                    </Div>

                    <Row>
                        <Col xs="12" sm="6" lg="4">
                            <Button onClick={() => wizardService.backToDashboard()}>
                                <FontAwesomeIcon icon={faHome} />{' '}
                                {t('buttons.backToDashboard.label')}
                            </Button>
                        </Col>
                        <Col xs="12" sm="6" lg="4">
                            <Button className="btn-primary" onClick={() => wizardService.goPay(validateForm, setFieldTouched, t, this.goPay)}>
                                <FontAwesomeIcon icon={faWallet} />{' '}
                                {t('buttons.pay.label')}
                            </Button>
                        </Col>
                        <Col xs="12" sm="6" lg="4">
                            <Button onClick={this.goToDownload}>
                                <FontAwesomeIcon icon={faDownload} />{' '}
                                {t('buttons.download.label')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Div>)}
        </Formik>)
    }

    async goPay(): Promise<void> {
        const payment = await this.props.payAdditionalServices(this.props.taxReturn.id, this.props.lang);
        if (payment.gatewayUrl) {
            const checkout = await paymentService.checkout(payment.gatewayUrl);
            console.log(checkout);
        }
        history.push('/download/' + this.props.taxReturn.id);
    }
}

const StyledButton = glamorous(Button)({
    marginBottom: '24px',
    fontSize: '16px !important',
});

export default translate('translations')(AdditionalServicesForm);
