export function getApiConstants(name: string) {
	return {
		REQUEST: name + '_REQUEST',
		SUCCESS: name + '_SUCCESS',
		FAILURE: name + '_FAILURE',
	}
}

export const API = {
	LOGIN: '/login',
	LOGOUT: '/logout',
	PERSONS: '/persons',
	LANGUAGES: '/form-fields',
	CATALOGS: '/catalogs',
	TAX_RETURN: '/tax-returns'
};
