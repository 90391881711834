import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/sk';
import 'moment/locale/uk';
import 'moment/locale/vi';
import { reactI18nextModule } from 'react-i18next';
import constants from '../utils/constants';
import langConstants from './langConstants';

const defaultLanguage = langConstants.DEFAULT_LANGUAGE;

i18next
	.use(reactI18nextModule)
	.init({
		interpolation: {
			escapeValue: false,
			format: function(value, format: any, lng) {
				if (format.match('::')) {
					const parts = format.split('::');
					if (parts.length === 2) {
						if (parts[0] === 'date') {
							return moment(value).format(parts[1]);
						}
					}
				}

				return value;
			}
		},
		lng: defaultLanguage,
		fallbackLng: defaultLanguage,
		ns: ['translations'],
		defaultNS: 'translations',
		//debug: process.env.REACT_APP_MODE === constants.MODE_DEVELOPMENT
		debug: false
	});

setLanguage(defaultLanguage);
i18next.on('languageChanged', setLanguage);

function setLanguage(lng: string) {
	moment.locale(lng);
}

export default i18next;