import { combineReducers } from 'redux';

import auth from './app/authReducer';
import wizard from './app/wizardReducer';
import lang from './app/langReducer';

export const appReducer =  combineReducers({
	auth,
	wizard,
	lang
});
export default appReducer;