import apiConfig from '../config/apiConfig';
import history from '../utils/history';
import i18n from '../langs/i18n';
import  { addMessage, messagesConstants } from './flashMessages';
import { forOwn } from 'lodash';

export const apiService = {
	get,
	post,
	put,
	patch,
	remove,
	uploadFile,
	handleResponse,
	transformEntities,
};

export function get(url: string, options = undefined) {
	const mergedOptions = Object.assign({}, getRequestDefaultOptions(), options);

	return fetchUrl(url, mergedOptions);
}

export function post(url: string, data: any = undefined, options = undefined, supressToastErrors = false) {
	const mergedOptions = Object.assign({}, getUpdateRequestDefaultOptions('POST', data), options);

	return fetchUrl(url, mergedOptions, supressToastErrors);
}

export function put(url: string, data: any, options = undefined) {
	const mergedOptions = Object.assign({}, getUpdateRequestDefaultOptions('PUT', data), options);

	return fetchUrl(url, mergedOptions);
}

export function patch(url: string, data: any, options = undefined, supressToastErrors = false) {
	const mergedOptions = Object.assign({}, getUpdateRequestDefaultOptions('PATCH', data), options);

	return fetchUrl(url, mergedOptions, supressToastErrors);
}

export function remove(url: string, options = undefined) {
	const mergedOptions = Object.assign({}, getUpdateRequestDefaultOptions('DELETE'), options);

	return fetchUrl(url, mergedOptions);
}

export function uploadFile(url: string, formData: any, options = undefined) {
	const mergedOptions = Object.assign({}, getUploadRequestDefaultOptions('POST', formData), options);

	return fetchUrl(url, mergedOptions);
}

function fetchUrl(url: string, options = undefined, supressToastErrors = false) {
	return fetch(
		(url.match(/^https?:\/\//) ? '' : apiConfig.url) + url, options
	).then((response) => handleResponse(response, supressToastErrors));
}

function handleResponse(response, supressToastErrors = false) {
	if (!response.ok) {
		const { status, statusText } = response;
		if (status === 401) {
			history.replace('/login');
		}

		return response.json().then(
			({ message, errors }) => {
				if (Array.isArray(errors)) {
					dispatchMessages(errors, supressToastErrors);
				} else if (typeof errors === 'object' && errors !== null) {
					Object.keys(errors).forEach((key) => dispatchMessages(errors[key], supressToastErrors, key));
				} else {
					dispatchMessages([message], supressToastErrors);
				}

				return Promise.reject(errors);
			},
			() => {
				if (!supressToastErrors) {
					addMessage(i18n.t('error.label'), messagesConstants.TYPE_ERROR);
				}
			}
		);

	}

	return response.json();
}

function dispatchMessages(messages, supressToastErrors = false, k = undefined) {
	forOwn(messages, (message, key) => {
		if (typeof message === 'object' ) {
			dispatchMessages(message, supressToastErrors, [k, key].join('.'));
		} else {
			if (!supressToastErrors) {
				addMessage(i18n.t(message), messagesConstants.TYPE_ERROR);
			}
		}
	});
}

export function transformEntities(entities) {
	const entitiesObject = Object.assign({}, ...entities.map(entity => ({[entity['id']]: entity})));

	return {
		byId: entitiesObject,
		allIds: Object.keys(entitiesObject),
	};
}

export function extractIdsFromEntities(entities) {
	return entities.map(entity => entity.id);
}

function getRequestDefaultOptions() {
	return {
		cache: 'no-cache',
		credentials: 'include',
		headers: {
			'content-type': 'application/json',
		},
	};
}

function getUpdateRequestDefaultOptions(method: string, data = undefined) {
	return {
		...getRequestDefaultOptions(),
		method: method,
		body: data ? JSON.stringify(data) : undefined,
	};
}

function getUploadRequestDefaultOptions(method: string, formData: any) {
	return {
		cache: 'no-cache',
		credentials: 'include',
		method: method,
		body: formData,
	};
}

export default apiService;
