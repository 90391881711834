import React from 'react';
import { connect } from 'react-redux';
import usersActions from './actions/usersActions';
import SignUpFormContainer from './components/SignUpForm/SignUpFormContainer';
import SignUpLayout from '../../layouts/SignUpLayout';

interface ISignUpPageProps {
	logout: Function;
	lang: string;
}

export class SignUpPage extends React.Component<ISignUpPageProps, any> {

	render() {
		return (
		    <SignUpLayout>
                <SignUpFormContainer />
			</SignUpLayout>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {
        lang: state.app.lang.lang,
	};
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {
		logout: (language: string) => {
			return dispatch(usersActions.logout(language));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SignUpPage);