const ROUTES = {
    AUTH: {
        LOGIN: '/login',
        CONFIRM: '/confirm-user',
        SIGN_UP: '/sign-up',
        RESET_PASSWORD: '/reset-password',
        RESET_PASSWORD_REQUEST: '/reset-password-request'
    },
    DASHBOARD: '/',
    WIZARD: {
        PAGE: '/wizard',
        STEP: [
            '/wizard/create',
            '/wizard/step-one',
            '/wizard/step-two',
            '/wizard/step-three',
            '/wizard/step-four',
            '/wizard/step-five',
            '/wizard/additional-services',
        ],
    },
    DOWNLOAD: '/download/:trId',
};

const constants = {
    MODE_DEVELOPMENT: 'development',
    MODE_PRODUCTION: 'production',
    FORMAT_DATE: 'YYYY-MM-DD',
    FORMAT_DATETIME: 'YYYY-MM-DD HH:mm:ss',
    DEFAULT_CURRENCY: 'Kč',
    WEB: {
        CS: {
            TERMS_OF_SERVICE: '/cs/podminky-uziti-sluzby',
            PERSONAL_DATA_PROCESSING: '/cs/ochrana-osobnich-udaju',
        },
        SK: {
            TERMS_OF_SERVICE: '/sk/podminky-uziti-sluzby',
            PERSONAL_DATA_PROCESSING: '/sk/ochrana-osobnich-udaju',
        },
        EN: {
            TERMS_OF_SERVICE: '/terms-and-conditions',
            PERSONAL_DATA_PROCESSING: '/privacy-policy',
        },
        DE: {
            TERMS_OF_SERVICE: '/de/terms-and-conditions',
            PERSONAL_DATA_PROCESSING: '/de/privacy-policy',
        },
        IT: {
            TERMS_OF_SERVICE: '/it/terms-and-conditions',
            PERSONAL_DATA_PROCESSING: '/it/privacy-policy',
        },
        PL: {
            TERMS_OF_SERVICE: '/pl/terms-and-conditions',
            PERSONAL_DATA_PROCESSING: '/pl/privacy-policy',
        },
        RU: {
            TERMS_OF_SERVICE: '/ru/terms-and-conditions',
            PERSONAL_DATA_PROCESSING: '/ru/privacy-policy',
        },
        UK: {
            TERMS_OF_SERVICE: '/uk/terms-and-conditions',
            PERSONAL_DATA_PROCESSING: '/uk/privacy-policy',
        },
        VI: {
            TERMS_OF_SERVICE: '/vi/terms-and-conditions',
            PERSONAL_DATA_PROCESSING: '/vi/privacy-policy',
        }
    },
    ROUTES,
    TAX_RETURN_STATE: {
        CREATED: 'created',
        PAID: 'paid',
    }
};

export default constants;