import {
    faPlus,
    faHourglassHalf,
    faExclamationTriangle,
    faEdit,
    faRedo,
    faSpinner, faDownload
} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import glamorous, { Div } from 'glamorous';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {Button, Row, Col, Input, ModalHeader, ModalBody, ModalFooter, Modal} from 'reactstrap';
import theme from '../../styles/theme';
import constants from '../../utils/constants';
import history from '../../utils/history';
import { trActions } from '../Common/actions/trActions';
import { ITaxReturn } from '../Dashboard/components/TaxReturn/ITaxReturn';
import AppLayout from "../../layouts/AppLayout";
import apiConfig from "../../config/apiConfig";
import HelpIcon from '../../components/HelpIcon/HelpIcon';
import axios from 'axios';
import fileDownload from 'js-file-download';
import {Formik} from 'formik';
import  { addMessage, messagesConstants } from '../../services/flashMessages';
import Attachments from "../../components/Attachments/Attachments";

interface IDownloadPageProps {
    taxReturn: ITaxReturn;
    xmlAttachments: any;
    lang: string;
    load: Function;
    loadXmlAttachments: Function;
    attachFile: Function;
    deleteAttachment: Function;
    validate: Function;
    match: any;
}

interface IDownloadState {
    valid: boolean;
    validating: boolean;
    validated: boolean;
    reloaded: boolean;
    downloading: string;
    attachmentsLoaded: boolean;
    xmlWarningOpened: boolean;
}

const NoDownloading = 'none';

class DownloadPage extends React.Component<WithNamespaces & IDownloadPageProps & RouteComponentProps, IDownloadState> {

    readonly state = {
        valid: false,
        validating: false,
        validated: false,
        reloaded: false,
        downloading: NoDownloading,
        attachmentsLoaded: false,
        xmlWarningOpened: false,
    };

    componentWillMount() {
        const queryParams = this.props.match.params;
        if (queryParams.trId) {
            this.props.load(queryParams.trId);
        } else {
            history.replace(constants.ROUTES.DASHBOARD);
        }
    }

    componentDidUpdate() {
        const {taxReturn, xmlAttachments, attachFile, load, validate} = this.props;

        if (taxReturn
            && taxReturn.state
            && taxReturn.state !== constants.TAX_RETURN_STATE.PAID
            && !this.state.reloaded
        ) {
            setTimeout(() => {
                // periodically reload the tax return
                load(taxReturn.id)
                this.setState((oldState) => ({
                    ...oldState,
                    reloaded: true
                }));
            }, 10000);
        }

        if (taxReturn.id && taxReturn.state === constants.TAX_RETURN_STATE.PAID && !this.state.validated && !this.state.validating) {
            this.setState((oldState) => ({
                ...oldState,
                validating: true,
            }));
            validate(taxReturn.id)
                .then((values) => {
                    if ((taxReturn.hasDatabox || taxReturn.submitViaDatabox) && !this.state.attachmentsLoaded) {
                        this.props.loadXmlAttachments(taxReturn.id);
                        this.setState((oldState) => ({
                            attachmentsLoaded: true,
                        }));
                    }
                    this.setState((oldState) => ({
                        ...oldState,
                        valid: true,
                        validating: false,
                        validated: true,
                    }));
                })
                .catch((errors) => {
                    this.setState((oldState) => ({
                        ...oldState,
                        valid: false,
                        validating: false,
                        validated: true,
                    }));
                });
        }
    }

    doDownload = (key: string, url: string, fileName: string = '') => {
        if (this.state.downloading !== NoDownloading) {
            return;
        }

        this.setState((oldState) => ({
            ...oldState,
            downloading: key,
        }));
        axios.get(
            url,
            { responseType: 'blob', withCredentials: true }
        ).then((response) => {
            this.setState((oldState) => ({
                ...oldState,
                downloading: NoDownloading
            }));
            fileDownload(response.data, fileName ? fileName : this.props.t(`download.${key}.label`) + '.pdf');
        });
    };

    downloadLetter = () => {
        this.doDownload(
            'letter',
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/summary-letter-pdf/${this.props.lang}`
        );
    };

    downloadXmlLetter = () => {
        this.doDownload(
            'xmlLetter',
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/summary-letter-pdf/${this.props.lang}?forDatabox=1`,
        );
    };

    downloadTaxReturnPdf = () => {
        this.doDownload(
            'pdf',
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/pdf`
        );

    };

    downloadXml = () => {
        this.doDownload(
            'xml',
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/xml`,
            this.props.t(`download.xmlfile.label`) + '.xml'
        );
    };

    downloadInvoicePdf = () => {
        this.doDownload(
            'invoicePdf',
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/invoice-pdf/cs`
        );
    };

    downloadSocial = () => {
        this.doDownload(
            'social',
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/pdf?socialOnly=1`
        );
    };

    downloadHealth = () => {
        this.doDownload(
            'health',
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/pdf?healthOnly=1`
        );
    };

    goToSummary = () => {
        history.push('/wizard/step-five/' + this.props.taxReturn.id);
    };

    goToAdditionalServices = () => {
        history.push('/wizard/additional-services/' + this.props.taxReturn.id);
    };

    reload = () => {
        location.reload();
    };

    toggleXmlWarning = () => {
        this.setState((prevState) => ({
            ...prevState,
            xmlWarningOpened: !prevState.xmlWarningOpened,
        }));
    };

	render() {
		const { t, taxReturn, lang, xmlAttachments } = this.props;

		if (!taxReturn.id) {
		    return null;
        }

		return (
		    <AppLayout>
                <h1>
                    {taxReturn.personalData.firstName} {taxReturn.personalData.lastName} {taxReturn.year}
                </h1>
                {!taxReturn.isSubmitNotificationSent && <RedP>{t(taxReturn.printingRequired ? 'download.printTitle.placeholder' : 'download.title.placeholder')}</RedP>}
                {taxReturn.isSubmitNotificationSent && <GreenP>{t('download.submitted.placeholder')}</GreenP>}
                {taxReturn.state === constants.TAX_RETURN_STATE.CREATED && <Div className="formSection">
                    {!this.state.reloaded && <Div>
                        <h3>
                            <FontAwesomeIcon icon={faHourglassHalf} style={{color: theme.colors.blue}} />{' '}
                            {t('paymentPending.label')}
                        </h3>
                        <p>{t('paymentPending.placeholder')}</p>
                    </Div>}
                    {this.state.reloaded && <Div>
                        <h3>
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{color: theme.colors.red}} />{' '}
                            {t('notPaid.label')}
                        </h3>
                        <p>{t('notPaid.placeholder')}</p>
                        <Div>
                            <StyledButton className="btn-primary" title={t('reload.label')} onClick={this.reload}>
                                <FontAwesomeIcon icon={faRedo} style={{color: theme.colors.white}} /> {t('reload.label')}
                            </StyledButton>
                        </Div>
                        <Div>
                            <StyledButton className="btn-primary" title={t('backToSummary.label')} onClick={this.goToSummary}>
                                <FontAwesomeIcon icon={faEdit} style={{color: theme.colors.white}} /> {t('backToSummary.label')}
                            </StyledButton>
                        </Div>
                    </Div>}
                </Div>}
                {taxReturn.state === constants.TAX_RETURN_STATE.PAID && <Div>
                    {this.state.validated && this.state.valid && <Div>
                        <Div className="formSection">
                            <Row>
                                <Col xs="12" lg="6">
                                    <h3>{t('download.print.label')}</h3>
                                    <p className="noPadding">{t('download.print.placeholder')}</p>
                                    <Row>
                                        <Col xs="12">
                                            <StyledButton className="btn-primary" disabled={this.state.downloading !== NoDownloading} title={t('download.letter.label')} onClick={this.downloadLetter}>
                                                {this.state.downloading === 'letter' && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                                {this.state.downloading !== 'letter' && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                                {' '}{t('download.letter.label')}
                                            </StyledButton>
                                            <HelpIcon helpKey="download.letter" taxReturn={taxReturn} t={t} />
                                        </Col>
                                        <Col xs="12">
                                            <StyledButton className="btn-primary" disabled={this.state.downloading !== NoDownloading} title={t('download.pdf.label')} onClick={this.downloadTaxReturnPdf}>
                                                {this.state.downloading === 'pdf' && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                                {this.state.downloading !== 'pdf' && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                                {' '}{t('download.pdf.label')}
                                            </StyledButton>
                                            <HelpIcon helpKey="download.pdf" taxReturn={taxReturn} t={t} />
                                        </Col>
                                    </Row>
                                </Col>
                                {(taxReturn.hasDatabox || taxReturn.submitViaDatabox) && <Col xs="12" lg="6">
                                    <h3>
                                        {t('download.xml.label')}
                                        <HelpIcon helpKey="download.xml" taxReturn={taxReturn} t={t} />
                                    </h3>
                                    <p className="noPadding">{t('download.xml.placeholder')}</p>
                                    <Row>
                                        <Col xs="12">
                                            <StyledButton className="btn-primary" disabled={this.state.downloading !== NoDownloading} title={t('download.xmlLetter.label')} onClick={this.downloadXmlLetter}>
                                                {this.state.downloading === 'xmlLetter' && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                                {this.state.downloading !== 'xmlLetter' && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                                {' '}{t('download.xmlLetter.label')}
                                            </StyledButton>
                                            <HelpIcon helpKey="download.xmlLetter" taxReturn={taxReturn} t={t} />
                                        </Col>
                                        <Col xs="12">
                                            <StyledButton className="btn-primary" disabled={this.state.downloading !== NoDownloading} title={t('download.xmlfile.label')} onClick={this.downloadXml}>
                                                {this.state.downloading === 'xml' && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                                {this.state.downloading !== 'xml' && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                                {' '}{t('download.xmlfile.label')}
                                            </StyledButton>
                                            <HelpIcon helpKey="download.xmlfile" taxReturn={taxReturn} t={t} />
                                        </Col>
                                        {/*<Modal isOpen={this.state.xmlWarningOpened} toggle={this.toggleXmlWarning} size="lg">*/}
                                        {/*    <ModalHeader>{t('download.xmlWarning.label')}</ModalHeader>*/}
                                        {/*    <ModalBody>{t('download.xmlWarning.placeholder')}</ModalBody>*/}
                                        {/*    <ModalFooter>*/}
                                        {/*        <Button color="secondary" onClick={this.toggleXmlWarning}>{t('close.label')}</Button>*/}
                                        {/*    </ModalFooter>*/}
                                        {/*</Modal>*/}
                                    </Row>
                                    <Row>
                                        {taxReturn.socialInsurance.summaryRequired && <Col xs="12">
                                            <StyledButton className="btn-primary" disabled={this.state.downloading !== NoDownloading} title={t('download.social.label')} onClick={this.downloadSocial}>
                                                {this.state.downloading === 'social' && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                                {this.state.downloading !== 'social' && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                                {' '}{t('download.social.label')}
                                            </StyledButton>
                                            <HelpIcon helpKey="download.social" taxReturn={taxReturn} t={t} />
                                        </Col>}
                                        {taxReturn.socialInsurance.healthInsurance.summaryRequired && <Col xs="12">
                                            <StyledButton className="btn-primary" disabled={this.state.downloading !== NoDownloading} title={t('download.health.label')} onClick={this.downloadHealth}>
                                                {this.state.downloading === 'health' && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                                {this.state.downloading !== 'health' && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                                {' '}{t('download.health.label')}
                                            </StyledButton>
                                            <HelpIcon helpKey="download.health" taxReturn={taxReturn} t={t} />
                                        </Col>}
                                    </Row>
                                    <Formik
                                        initialValues={xmlAttachments}
                                        onSubmit={values => console.log(values)}
                                    >
                                        {formik => (<Div>
                                          <h3>{t('download.xmlAttachments.title.label')}</h3>
                                          <p>{t('download.xmlAttachments.title.placeholder')}</p>
                                          <Attachments
                                            attachments={xmlAttachments}
                                            taxReturn={taxReturn}
                                            attachFile={this.props.attachFile}
                                            deleteAttachment={this.props.deleteAttachment}
                                            t={t}
                                          />
                                        </Div>)}
                                    </Formik>
                                </Col>}
                            </Row>
                        </Div>
                        <Div className="formSection">
                            <Row>
                                <Col xs="12" sm="6" lg="4">
                                    <StyledButton className="btn-primary" disabled={this.state.downloading !== NoDownloading} title={t('download.invoicePdf.label')} onClick={this.downloadInvoicePdf}>
                                        {this.state.downloading === 'invoice' && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                        {this.state.downloading !== 'invoice' && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                        {' '}{t('download.invoicePdf.label')}
                                    </StyledButton>
                                    <HelpIcon helpKey="download.invoicePdf" taxReturn={taxReturn} t={t} />
                                </Col>
                                <Col xs="12" sm="6" lg="4">
                                    <StyledButton className="btn-primary" title={t('buttons.editTaxReturn.label')} onClick={this.goToSummary}>
                                        <FontAwesomeIcon icon={faEdit} style={{color: theme.colors.white}} /> {t('buttons.editTaxReturn.label')}
                                    </StyledButton>
                                </Col>
                                {(!taxReturn.printingRequired || !taxReturn.certifiedCheckRequired || !taxReturn.onMyBehalfRequired) && <Col xs="12" sm="6" lg="4">
                                    <StyledButton className="btn-primary" title={t('additionalServices.label')} onClick={this.goToAdditionalServices}>
                                        <FontAwesomeIcon icon={faPlus} style={{color: theme.colors.white}} /> {t('additionalServices.label')}
                                    </StyledButton>
                                </Col>}
                            </Row>
                            <p>&nbsp;</p>
                            <p><strong>{t('thankYou.label')}</strong></p>
                            <p>{t('thankYou.placeholder')}</p>
                            <p>
                                <a href="https://www.facebook.com/czechtaxesonline/" target="_blank">
                                    <FontAwesomeIcon icon={faFacebook} style={{color: theme.colors.blue}} /> {t('shareOnFb.label')}
                                </a>
                            </p>
                        </Div>
                    </Div>}
                    {this.state.validated && !this.state.valid && <Div className="formSection">
                        <h3>
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{color: theme.colors.red}} />{' '}
                            {t('notValid.label')}
                        </h3>
                        <p>{t('notValid.placeholder')}</p>
                        <Div>
                            <StyledButton className="btn-primary" title={t('backToSummary.label')} onClick={this.goToSummary}>
                                <FontAwesomeIcon icon={faEdit} style={{color: theme.colors.white}} /> {t('backToSummary.label')}
                            </StyledButton>
                        </Div>
                    </Div>}
                </Div>}
            </AppLayout>
		)
	}
}

const StyledButton = glamorous(Button)({
    marginBottom: '24px',
    fontSize: '16px !important',
});

const RedP = glamorous('p')({
    color: theme.colors.red,
    fontWeight: 'bold',
});

const GreenP = glamorous('p')({
    color: theme.colors.green,
    fontWeight: 'bold',
});

export const mapStateToProps = (state: any, props: any) => {
    return {
        taxReturn: state.entities.taxReturn,
        xmlAttachments: state.entities.xmlAttachments,
        lang: state.app.lang.lang,
    };
};

export const mapDispatchToProps = (dispatch: Function, props: any) => {
    return {
        load: (id: string) => {
            return dispatch(trActions.onLoad(id));
        },
        loadXmlAttachments: (id: string) => {
            return dispatch(trActions.xmlAttachments(id));
        },
        attachFile: (id: string, type: string, file: File) => {
            return dispatch(trActions.attachFile(id, type, file, 'download'));
        },
        deleteAttachment: (taxReturnId: string, attachmentId: string) => {
            return dispatch(trActions.deleteAttachment(taxReturnId, attachmentId, 'download'));
        },
        validate: (id: string) => {
            return dispatch(trActions.onValidate(id, 5, true));
        },
    };
};

export default translate('translations')(withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(DownloadPage)) as any);
