import React from 'react';
import glamorous,{Div} from 'glamorous';
import {Col, Row, Container} from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import constants from '../../../../../utils/constants';
import CatalogLabel from '../../../../../components/CatalogComboField/CatalogLabel';

interface ISelfEmploymentSummaryProps {
    selfEmployment: any;
    taxReturnId: string;
    thousandSeparator: string;
    czkSuffix: string;
    t: Function;
}

export default class SelfEmploymentSummary extends React.Component<ISelfEmploymentSummaryProps> {
	render() {
        const { selfEmployment, taxReturnId, t, thousandSeparator, czkSuffix } = this.props;


        return (<Div className="formSection summary">
            <h3 className="noCheckbox">{t('sections.selfEmployment.label')}</h3>
            <Div className="subForm">
                <Row>
                    <Col sm="6">
                        {t('selfEmployment.tradeLicense.ico.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong>{selfEmployment.tradeLicense.ico}</strong>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        {t('selfEmployment.prevailingActivityName.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong>
                            <CatalogLabel
                                catalogName="type-of-activity"
                                value={selfEmployment.prevailingActivityName}
                            />
                        </strong>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        {t('selfEmployment.income.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong><NumberFormat value={selfEmployment.income} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        {t('selfEmployment.expensesType.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong>{t(`selfEmployment.expensesTypes.${selfEmployment.expensesType}.label`)}</strong>
                    </Col>
                </Row>
                {selfEmployment.expensesType === 'real' && <Row>
                    <Col sm="6">
                        {t('selfEmployment.expenses.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong><NumberFormat value={selfEmployment.expenses} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                    </Col>
                </Row>}

                {selfEmployment.otherActivities.map((activity, index) => (<Div>
                    <Row key={index}>
                        <Col xs="6">
                            {t('selfEmployment.otherActivities.activityName.label')}
                        </Col>
                        <Col xs="6">
                            <strong>
                                <CatalogLabel
                                    catalogName="type-of-activity"
                                    value={activity.activityName}
                                />
                            </strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            {t('selfEmployment.otherActivities.income.label')}
                        </Col>
                        <Col xs="6">
                            <strong><NumberFormat value={activity.income} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>
                        <Row>
                        <Col xs="6">
                            {t('selfEmployment.otherActivities.expensesType.label')}
                        </Col>
                        <Col xs="6">
                            <strong>{t(`selfEmployment.expensesTypes.${activity.expensesType}.label`)}</strong>
                        </Col>
                    </Row>
                    {activity.expensesType === 'real' && <Row>
                        <Col xs="6">
                            {t('selfEmployment.otherActivities.expenses.label')}
                        </Col>
                        <Col xs="6">
                            <strong><NumberFormat value={activity.expenses} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>}
                </Div>))}

                <h3>{t('selfEmployment.about.label')}{' '}</h3>

                {selfEmployment.tradeLicense.commencementDate && <Row>
                    <Col sm="6">
                        {t('selfEmployment.tradeLicense.commencementDate.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{selfEmployment.tradeLicense.commencementDate}</strong>
                    </Col>
                </Row>}
                {selfEmployment.tradeLicense.interruptionDate && <Row>
                    <Col sm="6">
                        {t('selfEmployment.tradeLicense.interruptionDate.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{selfEmployment.tradeLicense.interruptionDate}</strong>
                    </Col>
                </Row>}
                {selfEmployment.tradeLicense.terminationDate && <Row>
                    <Col sm="6">
                        {t('selfEmployment.tradeLicense.terminationDate.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{selfEmployment.tradeLicense.terminationDate}</strong>
                    </Col>
                </Row>}
                {selfEmployment.tradeLicense.renewalDate && <Row>
                    <Col sm="6">
                        {t('selfEmployment.tradeLicense.renewalDate.label')}
                    </Col>
                    <Col sm="6">
                        <strong>{selfEmployment.tradeLicense.renewalDate}</strong>
                    </Col>
                </Row>}
                <Row>
                    <Col sm="6">
                        {t('selfEmployment.tradeLicense.numberOfActivityMonths.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong>{selfEmployment.tradeLicense.numberOfActivityMonths}</strong>
                    </Col>
                </Row>

                {selfEmployment.expensesType === 'real' && <SelfEmploymentStats>
                    <Row>
                        <Col xs="12" sm="6" lg="3" />
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.atCommencement.label')}
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.atEnd.label')}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.fixedAsset.label')}
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.fixedAssetAtCommencement} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.fixedAssetAtEnd} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.moneyInCash.label')}
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.moneyInCashAtCommencement} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.moneyInCashAtEnd} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.moneyOnBankAccount.label')}
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.moneyOnBankAccountAtCommencement} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.moneyOnBankAccountAtEnd} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.stocks.label')}
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.stocksAtCommencement} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.stocksAtEnd} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.receivables.label')}
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.receivablesAtCommencement} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.receivablesAtEnd} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.otherItems.label')}
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.otherItemsAtCommencement} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.otherItemsAtEnd} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.payables.label')}
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.payablesAtCommencement} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.payablesAtEnd} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.reserves.label')}
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.reservesAtCommencement} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.reservesAtEnd} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6" lg="3">
                            {t('selfEmployment.selfEmploymentStats.wages.label')}
                        </Col>
                        <Col xs="12" sm="6" lg="3">
                            <strong><NumberFormat value={selfEmployment.selfEmploymentStats.wages} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                        </Col>
                    </Row>
                </SelfEmploymentStats>}
                <Link to={constants.ROUTES.WIZARD.STEP[1] + '/' + taxReturnId}>{t('editThisSection.label')}</Link>
            </Div>
        </Div>)
	}
}

const SelfEmploymentStats = glamorous(Div)({
    textAlign: 'right',
});
