import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { trActions } from '../../../Common/actions/trActions';
import { listActions } from '../../actions/listActions';
import { ITaxReturn } from "./ITaxReturn";
import TaxReturnInner from './TaxReturnInner';

const mapStateToProps = (state: any) => {
    return {
    	loggedUser: state.app.auth.user
    }
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {
		onDelete: (taxReturn: ITaxReturn, loggedUser: any) => {
			return dispatch(trActions.onDelete(taxReturn, loggedUser));
        },
		onEdit: (taxReturn: ITaxReturn) => {
				return dispatch(listActions.onEdit(taxReturn, false));
			},
		onCopy: (taxReturn: ITaxReturn, targetYear: string) => {
		  return dispatch(trActions.copy(taxReturn.id, targetYear));
		},
		onCreateSupplementary: (taxReturn: ITaxReturn) => {
			return dispatch(trActions.createSupplementary(taxReturn.id));
		},
		onDetail: (taxReturn: ITaxReturn) => {
			return dispatch(listActions.onDetail(taxReturn));
		},
		onDownload: (taxReturn: ITaxReturn) => {
			return dispatch(listActions.onDownload(taxReturn));
		}
	};
};

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(TaxReturnInner));
