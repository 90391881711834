import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import usersActions from '../../actions/usersActions';

const mapStateToProps = (state: any) => {
	return {
		lang: state.app.lang.lang,
	};
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {
		signUp: (
			email: string,
			firstName: string,
            lastName: string,
			password: string,
			termsOfService: boolean,
			personalDataProcessing: boolean,
			isSubscribedToMailNotifications: boolean,
			language: string,
		) => {
			return dispatch(usersActions.register(
				email,
				firstName,
                lastName,
				password,
				termsOfService,
				personalDataProcessing,
				isSubscribedToMailNotifications,
                language
			));
		}
	};
};

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(SignUpForm));