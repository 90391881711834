import React from 'react';
import glamorous, { Span, Img, Div } from 'glamorous';
import theme from '../../styles/theme';
import { faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button, Modal, ModalBody, ModalFooter, Popover, PopoverBody} from "reactstrap";
import appConfig from "../../config/appConfig";
import {ITaxReturn} from "../../modules/Dashboard/components/TaxReturn/ITaxReturn";

interface IHelpLinkProps {
    helpKey: string;
    taxReturn: ITaxReturn;
    t: Function;
}

interface IHelpLinkState {
    popoverOpened: boolean;
    modalOpened: boolean;
}

class HelpLink extends React.Component<IHelpLinkProps, IHelpLinkState> {

    readonly state = {
        popoverOpened: false,
        modalOpened: false,
    };

    togglePopover = () => {
        this.setState(prevState => ({
            popoverOpened: !prevState.popoverOpened,
        }));
    };

    toggleModal = () => {
        this.setState(prevState => ({
            popoverOpened: false,
            modalOpened: !prevState.modalOpened,
        }));
    };

    render() {
        const {helpKey, taxReturn, t} = this.props;
        const target = helpKey.replace(/\./g, '-');
        const helpText= {
            __html: t(helpKey + '.helpText', {defaultValue: '', taxReturnYear: taxReturn ? taxReturn.year : '', followingYear: taxReturn ? taxReturn.year + 1 : ''}),
        };
        const helpImage = t(helpKey + '.helpImage', {defaultValue: ''});

        return (
            <Span>
                <HelpLinkText id={target} onClick={this.togglePopover}>
                    {t(helpKey + '.label')}
                </HelpLinkText>
                <Popover placement='bottom' isOpen={this.state.popoverOpened} target={target} toggle={this.togglePopover}>
                    <PopoverBody>
                        <CloseButton onClick={this.togglePopover}><FontAwesomeIcon size="lg" icon={faTimesCircle} style={{color: theme.colors.gray}} /></CloseButton>
                        {helpImage !== '' && helpText.__html !== '' && <HelpImg
                            onClick={this.toggleModal}
                            src={appConfig.formFieldsImgBaseUrl + helpImage} />}
                        {helpImage !== '' && helpText.__html === '' && <FullHelpImg
                            onClick={this.toggleModal}
                            src={appConfig.formFieldsImgBaseUrl + helpImage} />}
                        {helpText.__html !== '' && <Span dangerouslySetInnerHTML={helpText} />}
                        <Span className="cleared"> </Span>
                    </PopoverBody>
                </Popover>
                {helpImage !== '' && <Modal isOpen={this.state.modalOpened} toggle={this.toggleModal} size="lg">
                    <ModalBody>
                        <ModalImg width="100%" src={appConfig.formFieldsImgBaseUrl + helpImage} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>{t('close.label')}</Button>
                    </ModalFooter>
                </Modal>}
            </Span>
        );
    }
}

const HelpLinkText = glamorous(Span)({
    color: theme.colors.blue,
    display: 'block',
    fontWeight: 'bold',
    cursor: 'pointer',
    ':hover': {
        textDecoration: 'underline',
    }
});

const HelpImg = glamorous(Img)({
    float: 'left',
    maxWidth: '50%',
    marginRight: '10px',
    marginBottom: '10px',
    width: '100%',
    cursor: 'pointer',
});

const FullHelpImg = glamorous(Img)({
    width: '100%',
    cursor: 'pointer',
});

const ModalImg = glamorous(Img)({
    width: '100%',
})

const CloseButton = glamorous(Div)({
    position: 'absolute',
    top: '-0.5em',
    right: '-0.5em',
    backgroundColor: theme.colors.grayDark,
    borderRadius: '1em',
});

export default HelpLink;