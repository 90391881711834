import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { catalogActions } from './catalogActions';
import { ICatalogItem } from './interfaces';
import { langService } from '../../services/langService';

interface CatalogLabelProps {
    loadCatalogItems(name: string, lang: string);
    catalogs: ICatalogItem[];
    catalogName: string; // name of catalog
    lang: string;
    value?: string;
}

class CatalogLabel extends React.Component<WithNamespaces & CatalogLabelProps> {

    private langs = {};

    constructor(props, s) {
        super(props);
        this.state = {
            langs: {},
        };
    }

	componentWillMount() {
		this.props.loadCatalogItems(this.props.catalogName, langService.getSavedLang());
    }

    componentDidUpdate() {
        if (this.langs[this.props.catalogName] && this.langs[this.props.catalogName] !== this.props.lang) {
            this.props.loadCatalogItems(this.props.catalogName, this.props.lang);
        }
        this.langs[this.props.catalogName] = this.props.lang;
    }

    render () {
        const { catalogs, catalogName, value } = this.props;

        const items = catalogs && catalogs[catalogName] || [];
        const item = items.find((catalogItem) => catalogItem.code === value);

        if (item) {
            return item.label;
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state: any, props) => {
	return {
        catalogs: state.entities.catalogs,
        lang: state.app.lang.lang
    };
};

const mapDispatchToProps = (dispatch: Function, props) => {
	return {
        loadCatalogItems: (id: string, lang: string) => {
			return dispatch(catalogActions.onLoad(id, lang));
		}
    };
};

export default translate('translations')(connect(
	mapStateToProps,
	mapDispatchToProps
)(CatalogLabel)) as any;
