import { Field, FieldProps, getIn } from 'formik';
import { translate, WithNamespaces } from 'react-i18next';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import HelpIcon from "../../../../components/HelpIcon/HelpIcon";
import {Div} from 'glamorous';
import FormFieldError from "../../../../components/FormFieldError/FormFieldError";
import {ITaxReturn} from "../../../Dashboard/components/TaxReturn/ITaxReturn";

interface NumberOfMonthsProps {
    name: string;
    textKey?: string;
    required?: boolean;
    value?: any;
    max: number;
    validateForm?: Function;
    taxReturn: ITaxReturn;
}

class NumberOfMonthsInner extends React.Component<WithNamespaces & FieldProps & NumberOfMonthsProps> {
    render () {
        const {
            field,
            name,
            textKey,
            value,
            validateForm,
            required,
            max,
            t,
            form: { errors, touched },
            taxReturn
        } = this.props;

        const length = max ? max : 12;
        const months = (new Array(length).fill(1)).map((item, index) => length - index);

        const _textKey = textKey ? textKey : field.name;
        const hasHelp = t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''});

        const onChange = (eventOrPath) => {
            field.onChange(eventOrPath);
            setTimeout(validateForm, 100);
        };
        return (<Field name={name} value={value}>
            {({ form }) => (<Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t(_textKey + '.label')}{required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon helpKey={_textKey} taxReturn={taxReturn} t={t}/>}
                    </Label>
                    <select {...field} onChange={onChange} className={field.value ? null : 'empty'}>
                        <option value="">{t(_textKey + '.placeholder')}</option>
                        {months.map((month, index) => (
                            <option key={month} value={month}>{month}</option>
                        ))}
                    </select>
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={_textKey} isValidating={form.isValidating} t={t}/>
            </Div>)}
        </Field>);
    }
}

class NumberOfMonths extends React.Component<WithNamespaces & NumberOfMonthsProps> {
    render () {
        const { name, ...rest } = this.props;
        return (
            <Field name={name} fieldName={name} {...rest} component={NumberOfMonthsInner} />
        );
    }
}

export default translate('translations')(NumberOfMonths) as any;
