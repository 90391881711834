import { ITaxReturn } from '../../Dashboard/components/TaxReturn/ITaxReturn';
import trService from '../services/trService';
import trConstants from './trConstants';
import {listActions} from "../../Dashboard/actions/listActions";

export const trActions = {
    onDelete,
    onSave,
    onPatch,
    onValidate,
    pay,
    payAdditionalServices,
    loadMy,
    onLoad,
    xmlAttachments,
    certifiedCheckAttachments,
    onMyBehalfAttachments,
    createEmpty,
    attachFile,
    deleteAttachment,
    copy,
    createSupplementary,
};

function onDelete(item: any, loggedUser: any) {
	return (dispatch: Function) => {

		dispatch({type: trConstants.DELETE.REQUEST, item});

		return trService.remove(item.id)
			.then(
				result => {
					dispatch({type: trConstants.DELETE.SUCCESS, result});
                    dispatch(loadMy(loggedUser.id));
					return result;
				},
				(error: any) => dispatch({type: trConstants.DELETE.FAILURE, error}),
			);
	}
}

function createEmpty(taxReturn: ITaxReturn) {
	return (dispatch: Function) => {

		dispatch({type: trConstants.CREATE_EMPTY.REQUEST, taxReturn});

		return trService.createEmpty(taxReturn)
			.then(
				result => {
					dispatch({type: trConstants.CREATE_EMPTY.SUCCESS, result});
					return result;
				},
				(error: any) => dispatch({type: trConstants.CREATE_EMPTY.FAILURE, error}),
			);
	}
}

function onSave(item: any) {
	return (dispatch: Function) => {
        dispatch({type: trConstants.SAVE.REQUEST, item});
    }
}

function onPatch(id: string, item: any, suppressToastErrors = false) {
	return (dispatch: Function) => {
        dispatch({type: trConstants.PATCH.REQUEST, item});

        return trService.patch(id, item, suppressToastErrors)
			.then(
				result => {
					dispatch({type: trConstants.PATCH.SUCCESS, result});
					return result;
				},
                (error: any) => {
					dispatch({type: trConstants.PATCH.FAILURE, error});
					return Promise.reject(error);
                },
			);
    }
}

function onValidate(id: string, step: number, suppressToastErrors = false) {
	return (dispatch: Function) => {
		dispatch({type: trConstants.VALIDATE.REQUEST, id});

		return trService.validate(id, step, suppressToastErrors)
			.then(
				result => {
					dispatch({type: trConstants.VALIDATE.SUCCESS, result});
					return result;
				},
				(error: any) => {
					dispatch({type: trConstants.VALIDATE.FAILURE, error});
					return Promise.reject(error);
				},
			);
	}
}

function pay(id: string, language?: string, suppressToastErrors = false) {
    return (dispatch: Function) => {
        dispatch({type: trConstants.PAY.REQUEST, id, language});

        return trService.pay(id, language, suppressToastErrors)
            .then(
                result => {
                    dispatch({type: trConstants.PAY.SUCCESS, result});
                    return result;
                },
                (error: any) => {
                    dispatch({type: trConstants.PAY.FAILURE, error});
                    return Promise.reject(error);
                },
            );
    }
}

function payAdditionalServices(id: string, language?: string, suppressToastErrors = false) {
    return (dispatch: Function) => {
        dispatch({type: trConstants.PAY.REQUEST, id, language});

        return trService.payAdditionalServices(id, language, suppressToastErrors)
            .then(
                result => {
                    dispatch({type: trConstants.PAY.SUCCESS, result});
                    return result;
                },
                (error: any) => {
                    dispatch({type: trConstants.PAY.FAILURE, error});
                    return Promise.reject(error);
                },
            );
    }
}

function loadMy(personId: string) {
	return (dispatch: Function) => {
        dispatch({type: trConstants.LOAD_MY.REQUEST, personId});

        return trService.loadMy(personId)
			.then(
				result => dispatch({type: trConstants.LOAD_MY.SUCCESS, personId, result}),
				(error: any) => dispatch({type: trConstants.LOAD_MY.FAILURE, error}),
            );
    }
}

function onLoad(id: string) {
	return (dispatch: Function) => {
        dispatch({type: trConstants.LOAD.REQUEST, id});

        return trService.load(id)
			.then(
				result => dispatch({type: trConstants.LOAD.SUCCESS, result}),
				(error: any) => dispatch({type: trConstants.LOAD.FAILURE, error}),
            );
    }
}

function xmlAttachments(id: string) {
    return (dispatch: Function) => {
        dispatch({type: trConstants.XML_ATTACHMENTS.REQUEST, id});

        return trService.xmlAttachments(id)
            .then(
                result => dispatch({type: trConstants.XML_ATTACHMENTS.SUCCESS, result}),
                (error: any) => dispatch({type: trConstants.XML_ATTACHMENTS.FAILURE, error}),
            );
    }
}

function certifiedCheckAttachments(id: string) {
  return (dispatch: Function) => {
    dispatch({type: trConstants.CERTIFIED_CHECK_ATTACHMENTS.REQUEST, id});

    return trService.certifiedCheckAttachments(id)
      .then(
        result => dispatch({type: trConstants.CERTIFIED_CHECK_ATTACHMENTS.SUCCESS, result}),
        (error: any) => dispatch({type: trConstants.CERTIFIED_CHECK_ATTACHMENTS.FAILURE, error}),
      );
  }
}

function onMyBehalfAttachments(id: string) {
    return (dispatch: Function) => {
        dispatch({type: trConstants.ON_MY_BEHALF_ATTACHMENTS.REQUEST, id});

        return trService.onMyBehalfAttachments(id)
            .then(
                result => dispatch({type: trConstants.ON_MY_BEHALF_ATTACHMENTS.SUCCESS, result}),
                (error: any) => dispatch({type: trConstants.ON_MY_BEHALF_ATTACHMENTS.FAILURE, error}),
            );
    }
}

function attachFile(id: string, type: string, file: File, reloadType: string) {
    const reloadActions = {
        download: xmlAttachments(id),
        stepFive: (dispatch) => {
            certifiedCheckAttachments(id)(dispatch);
            onMyBehalfAttachments(id)(dispatch);
        }
    };

	return dispatch => {
		dispatch({type: trConstants.ATTACH_FILE.REQUEST, id, file});

		const promise = trService.attachFile(id, type, file);

		return promise.then(
			(attachment) => reloadActions[reloadType](dispatch),
            (error) => dispatch({type: trConstants.ATTACH_FILE.FAILURE, error}),
		);
	};
}

function deleteAttachment(id: string, attachmentId, reloadType: string) {
    const reloadActions = {
        download: xmlAttachments(id),
        stepFive: (dispatch) => {
            certifiedCheckAttachments(id)(dispatch);
            onMyBehalfAttachments(id)(dispatch);
        }
    };

    return dispatch => {
        dispatch({type: trConstants.DELETE_ATTACHMENT.REQUEST, id, attachmentId});

        const promise = trService.deleteAttachment(id, attachmentId);

        return promise.then(
            () => reloadActions[reloadType](dispatch),
            (error) => dispatch({type: trConstants.DELETE_ATTACHMENT.FAILURE, error}),
        );
    };
}

function copy(id: string, targetYear: string, suppressToastErrors = false) {
  return (dispatch: Function) => {

    dispatch({type: trConstants.COPY.REQUEST, id});

    return trService.copy(id, targetYear)
      .then(
        result => {
          dispatch({type: trConstants.COPY.SUCCESS, result});
          dispatch(listActions.onEdit(result, true));
        },
        (error: any) => dispatch({type: trConstants.COPY.FAILURE, error}),
      );
  }
}

function createSupplementary(id: string) {
    return (dispatch: Function) => {
        dispatch({type: trConstants.CREATE_SUPPLEMENTARY.REQUEST, id});

        return trService.createSupplementary(id)
            .then(
                result => {
                    dispatch({type: trConstants.CREATE_SUPPLEMENTARY.SUCCESS, result});
                    dispatch(listActions.onEdit(result, true));
                },
                (error: any) => dispatch({type: trConstants.CREATE_SUPPLEMENTARY.FAILURE, error}),
            );
    }
}
