import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import {Button, Card, Modal, ModalBody, ModalFooter} from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import CardImg from 'reactstrap/lib/CardImg';
import CardTitle from 'reactstrap/lib/CardTitle';
import { ITaxReturn } from './ITaxReturn';
import glamorous, { Div, Img } from 'glamorous';
import { faPen, faFilePdf, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from "../../../../styles/theme";
import confirm from 'reactstrap-confirm';
import constants from "../../../../utils/constants";

export interface ITaxReturnProps {
    onDelete(item: ITaxReturn, loggedUser: any): void;
    onEdit(item: ITaxReturn): void;
    onCopy(item: ITaxReturn, targetYear: string): void;
    onCreateSupplementary(item: ITaxReturn): void;
    onDetail(item: ITaxReturn): void;
    onDownload(item: ITaxReturn): void;
    taxReturn: ITaxReturn;
    loggedUser: any;
}

interface ITaxReturnState {
    modalOpened: boolean;
}

class TaxReturn extends React.Component<WithNamespaces & ITaxReturnProps, ITaxReturnState> {

  readonly state = {
    modalOpened: false,
  };

  onDeleteClick = (taxReturn: ITaxReturn) => {
      const { t, onDelete, loggedUser } = this.props;
      confirm({
          title: (
              <h3>{t('removeTaxReturnConfirm.label')}</h3>
          ),
          message: t('removeTaxReturnConfirm.placeholder'),
      }).then((confirmed) => {
          if (confirmed) {
              onDelete(taxReturn, loggedUser);
         }
      });
  };

  mainAction = (taxReturn) => {
      if (taxReturn.state === constants.TAX_RETURN_STATE.CREATED) {
          this.props.onEdit(taxReturn);
      } else if (taxReturn.state === constants.TAX_RETURN_STATE.PAID) {
          this.props.onDownload(taxReturn);
      }
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpened: !prevState.modalOpened,
    }));
  };

  copy = (taxReturn) => {
    if (taxReturn.year < 2022) {
      this.toggleModal();
    } else {
      this.props.onCopy(taxReturn, '2023');
    }
  };

    createSupplementary = (taxReturn) => {
        this.props.onCreateSupplementary(taxReturn);
    };

	render() {
      const { t, taxReturn } = this.props;

      if (!this.props.taxReturn) {
          return null;
      }

      return (
          <Card>
              <CardImg top
                   onClick={() => this.mainAction(taxReturn)}
                   src={taxReturn.state === constants.TAX_RETURN_STATE.PAID ? '/done-return.svg' : '/open-return.svg'}
                   alt="open"
              />
              <CardBody>
                  <CardTitle>
                      {taxReturn.supplementaryStatement && taxReturn.supplementaryStatement.selected && <Div className="card-subtitle">
                          {t('dashboard.supplementary.label')}</Div>}
                      <Div>
                          {taxReturn.personalData.firstName} {taxReturn.personalData.lastName} {taxReturn.year}
                      </Div>
                      <Div>
                          <Button className="btn-primary" onClick={() => this.props.onEdit(taxReturn)}>
                            <FontAwesomeIcon icon={faPen} style={{color: theme.colors.white}} />{' '}
                            {t('dashboard.edit.label')}
                          </Button>
                          {taxReturn.state === constants.TAX_RETURN_STATE.CREATED && <Button className="btn-danger" onClick={() => this.onDeleteClick(taxReturn)}>
                            <FontAwesomeIcon icon={faTrash} style={{color: theme.colors.white}} />{' '}
                            {t('dashboard.delete.label')}
                          </Button>}
                          {taxReturn.year >= 2023 && taxReturn.state === constants.TAX_RETURN_STATE.PAID && <Button title={t('dashboard.createSupplementary.placeholder')} onClick={() => this.createSupplementary(taxReturn)}>
                              <FontAwesomeIcon icon={faCopy} style={{color: theme.colors.white}} />{' '}
                              {t('dashboard.createSupplementary.label')}
                          </Button>}
                      </Div>
                      {taxReturn.year < 2023 && taxReturn.state === constants.TAX_RETURN_STATE.PAID && <Div>
                        <Button title={t('dashboard.copy.placeholder')} onClick={() => this.copy(taxReturn)}>
                          <FontAwesomeIcon icon={faCopy} style={{color: theme.colors.white}} />{' '}
                          {t('dashboard.copy.label')}
                        </Button>
                        <Modal isOpen={this.state.modalOpened} toggle={this.toggleModal} size="sm">
                          <ModalBody>{t('dashboard.selectYear.label')}</ModalBody>
                          <ModalFooter>
                              <Button className="btn-primary" onClick={() => this.props.onCopy(taxReturn, '2021')}>2021</Button>
                              <Button className="btn-primary" onClick={() => this.props.onCopy(taxReturn, '2022')}>2022</Button>
                              <Button className="btn-primary" onClick={() => this.props.onCopy(taxReturn, '2023')}>2023</Button>
                              <Button color="secondary" onClick={this.toggleModal}>{t('close.label')}</Button>
                          </ModalFooter>
                        </Modal>
                      </Div>}
                  </CardTitle>
              </CardBody>
          </Card>
		)
	}
}

const InlineDiv = glamorous(Div)({
    display: 'inline-block',
    marginRight: '12px',
});

export default translate('translations')(TaxReturn);
