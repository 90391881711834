import { Field, FieldProps } from 'formik';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import {Div} from 'glamorous';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import {ITaxReturn} from "../../../Dashboard/components/TaxReturn/ITaxReturn";

interface IUniDisabledCheckboxProps {
    name: string;
    textKey?: string;
    placeholder?: boolean;
    taxReturn: ITaxReturn;
}

class UniDisabledCheckboxInner extends React.Component<WithNamespaces & FieldProps & IUniDisabledCheckboxProps> {

    render () {
        const {
            field,
            textKey,
            placeholder,
            t,
            form: {errors, touched},
            taxReturn
        } = this.props;
        const _textKey = textKey ? textKey : field.name;

        const onChange = (eventOrPath) => {
            field.onChange(eventOrPath);
        };

        return (<Field>
            {({ form }) => (<Div>
                <FormGroup className="checkbox">
                    <Div>
                        <Input
                            type="checkbox"
                            checked={false}
                            id={field.name}
                            {...field}
                            onChange={onChange}
                            readOnly={true}
                            disabled={true}
                        />
                        <Label for={field.name}>
                            {t(_textKey + '.label', { taxReturnYear: taxReturn.year, followingYear: taxReturn.year + 1 } )}{' '}
                        </Label>
                    </Div>
                    {placeholder && <p>{t(_textKey + '.placeholder')}</p>}
                </FormGroup>
            </Div>)}
        </Field>);
    }
}

const UniDisabledCheckbox: React.SFC<WithNamespaces & IUniDisabledCheckboxProps> = ({  name, ...rest }) => {
    return (
        <Field name={name} fieldName={name} {...rest} component={UniDisabledCheckboxInner} />
    )
};

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {};
}

export default translate('translations')(connect(
	mapStateToProps,
	mapDispatchToProps
)(UniDisabledCheckbox)) as any;
