import { getApiConstants } from '../../../utils/apiConstants';

export const trConstants = {
	DELETE: getApiConstants('DELETE_TAX_RETURN'),
	SAVE: getApiConstants('SAVE_TAX_RETURN'),
  PATCH: getApiConstants('PATCH_TAX_RETURN'),
  VALIDATE: getApiConstants('VALIDATE_TAX_RETURN'),
  PAY: getApiConstants('PAY'),
  LOAD_ALL: getApiConstants('LOAD_ALL'),
  LOAD_MY: getApiConstants('LOAD_MY'),
  LOAD: getApiConstants('LOAD_TAX_RETURN'),
  CREATE_EMPTY: getApiConstants('CREATE_EMPTY'),
  XML_ATTACHMENTS: getApiConstants('XML_ATTACHMENTS'),
  CERTIFIED_CHECK_ATTACHMENTS: getApiConstants('CERTIFIED_CHECK_ATTACHMENTS'),
  ON_MY_BEHALF_ATTACHMENTS: getApiConstants('ON_MY_BEHALF_ATTACHMENTS'),
  ATTACH_FILE: getApiConstants('ATTACH_FILE'),
  DELETE_ATTACHMENT: getApiConstants('DELETE_ATTACHMENT'),
  COPY: getApiConstants('COPY'),
  CREATE_SUPPLEMENTARY: getApiConstants('CREATE_SUPPLEMENTARY'),
};

export default trConstants;