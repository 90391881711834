import createReducer from '../../store/createReducer';
import usersConstants from '../../modules/Auth/actions/usersConstants';
import trConstants from '../../modules/Common/actions/trConstants';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export const authReducer = createReducer(initialState, {
	[usersConstants.LOGIN.REQUEST]: loginRequest,
	[usersConstants.LOGIN.SUCCESS]: loginSuccess,
	[usersConstants.LOGIN.FAILURE]: loginFailure,
	[usersConstants.LOGOUT.SUCCESS]: logoutSuccess
});

function loginRequest(state, action) {
	return {
		loggingIn: true,
	};
}

function loginSuccess(state, action) {
    if (window.hasOwnProperty('smartsupp')) {
        window['smartsupp'].call(null, 'name', action.user.firstName + ' ' + action.user.lastName);
        window['smartsupp'].call(null, 'email', action.user.email);
    }

	return {
		loggedIn: true,
		user: action.user,
	};
}

function loginFailure(state, action) {
	return {};
}

function logoutSuccess(state, action) {
	return {};
}

export default authReducer;