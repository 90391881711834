import trConstants from '../../modules/Common/actions/trConstants';
import createReducer from '../../store/createReducer';

const taxReturnInitialValueState = {};

function loadMyItemsSuccess(state, action) {
	const { result } = action;
	return result;
}

function loadMyItemsRequest(state, action) {
	return null;
}

function loadDetailOfTaxReturnSuccess(state, action) {
	const { result } = action;
	return result;

}

function createNewSuccess(state, action) {
	const { result } = action;
	return result;
}

export const taxReturn = createReducer(taxReturnInitialValueState, {
	[trConstants.LOAD.SUCCESS]: loadDetailOfTaxReturnSuccess,
  [trConstants.PATCH.SUCCESS]: loadDetailOfTaxReturnSuccess,
	[trConstants.LOAD.REQUEST]: (state, action) => {
		return taxReturnInitialValueState
	},
});

export const taxReturns = createReducer(null, {
	[trConstants.LOAD_MY.REQUEST]: loadMyItemsRequest,
	[trConstants.LOAD_MY.SUCCESS]: loadMyItemsSuccess
});

export const newTaxReturn = createReducer({}, {
	[trConstants.CREATE_EMPTY.SUCCESS]: createNewSuccess,
	[trConstants.COPY.SUCCESS]: createNewSuccess,
	[trConstants.CREATE_SUPPLEMENTARY.SUCCESS]: createNewSuccess
});
