import { translate, WithNamespaces } from 'react-i18next';
import classNames from 'classnames';
import * as React from 'react';
import { getI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { catalogActions } from '../components/CatalogComboField/catalogActions';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
const i18n = getI18n();

interface ILanguageSwitcherInnerProps {
    loadTranslations(language: string);
    currentLanguage: string;
    allLanguages: string[];
}

interface ILanguageSwitcherState {
    switcherOpened: boolean;
}

class LanguageSwitcherInner extends React.Component<ILanguageSwitcherInnerProps & WithNamespaces, ILanguageSwitcherState> {

    readonly state = {
      switcherOpened: false
    };

    toggle = () => {
        this.setState(prevState => ({
          switcherOpened: !prevState.switcherOpened,
        }));
    };

    render() {
        const { t, currentLanguage, allLanguages, loadTranslations } = this.props;

        return (
            <Dropdown isOpen={this.state.switcherOpened} toggle={this.toggle}>
                <DropdownToggle caret color={'primary'}>{t(`languageSwitcher.${currentLanguage}.label`)}</DropdownToggle>
                <DropdownMenu>
                    {allLanguages.map(language => {
                        return <DropdownItem key={language} onClick={() => loadTranslations(language)}>
                            {t(`languageSwitcher.${language}.label`)}
                        </DropdownItem>
                    })}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default translate('translations')(LanguageSwitcherInner);
