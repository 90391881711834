import { Field, FieldProps, getIn } from 'formik';
import { FormGroup, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import HelpIcon from "../../../../components/HelpIcon/HelpIcon";
import {Div} from 'glamorous';
import FormFieldError from "../../../../components/FormFieldError/FormFieldError";

interface IUniFileProps {
    name: string;
    type: string;
    fieldName: string;
    textKey?: string;
    required?: boolean;
    errorByName?: boolean;
    value?: any;
    attachFile: Function;
}

class UniFileInner extends React.Component<WithNamespaces & FieldProps & IUniFileProps> {
    onChange = (event) => {
        this.props.attachFile(event.currentTarget.files[0]);
    };

    render () {
        const {
            field,
            textKey,
            required,
            errorByName,
            t,
            value,
            attachFile,
            form: { errors, touched }
        } = this.props;
        const _textKey = textKey ? textKey : field.name;

        const hasHelp = t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''}) !== '';

        return (<Field>
            {({ form }) => (<Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t(_textKey + '.label')}{ required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon helpKey={_textKey} targetName={field.name} t={t} />}
                    </Label>
                    <Input
                        type="file"
                        {...field}
                        placeholder={t(_textKey + '.placeholder', {defaultValue: ''})}
                        onChange={this.onChange}
                    />
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={errorByName ? field.name : _textKey} isValidating={form.isValidating} t={t}/>
            </Div>)}
        </Field>);
    }
}

const UniFile: React.SFC<WithNamespaces & IUniFileProps> = ({ name, ...rest }) => {
    return (
        <Field name={name} {...rest} component={UniFileInner} />
    )
};

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {};
};

export default translate('translations')(connect(
	mapStateToProps,
	mapDispatchToProps
)(UniFile)) as any;
