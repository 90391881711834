import { trActions } from '../../Common/actions/trActions';
import { ITaxReturn } from '../../Dashboard/components/TaxReturn/ITaxReturn';
import { wizardActions } from '../actions/wizardActions';

export const mapStateToProps = (state: any, props: any) => {
    return {
        loggedUser: state.app.auth.user,
        taxReturn: state.entities.taxReturn,
        step: state.app.wizard.step,
        lang: state.app.lang.lang,
        certifiedCheckAttachments: state.entities.certifiedCheckAttachments,
        attachFile: Function,
    };
};

export const mapDispatchToProps = (dispatch: Function, props: any) => {
    return {
        load: (id: string) => {
            return dispatch(trActions.onLoad(id));
        },
        update: (id: string, taxReturn: ITaxReturn) => {
            return dispatch(trActions.onPatch(id, taxReturn, true));
        },
        validate: (id: string, step: number) => {
            return dispatch(trActions.onValidate(id, step, true));
        },
        pay: (id: string, language?: string) => {
            return dispatch(trActions.pay(id, language, true));
        },
        payAdditionalServices: (id: string, language?: string) => {
            return dispatch(trActions.payAdditionalServices(id, language, true));
        },
        setStep: (step: number) => {
            return dispatch(wizardActions.onStepChange(step));
        },
    };
};
