import apiService from './apiService';
import { apiConfig } from '../config/apiConfig';
import { API } from '../utils/apiConstants';

export const usersService = {
    register,
    confirm,
    login,
    logout,
    resetPasswordRequest,
    resetPassword,
    getUser,
    getLoggedUser
};

const confirmRegistrationUrl = apiConfig.frontendBaseUrl + '/confirm-user';

function register(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    termsOfService: boolean,
    personalDataProcessing: boolean,
    isSubscribedToMailNotifications: boolean,
    language: string,
    invitation = null
) {
    let url = `/register/?_locale=${language}&confirmationUrl=${encodeURIComponent(confirmRegistrationUrl)}`;

    if (invitation) {
        url += '&invitationToken=' + invitation;
    }

    return apiService.post(url, {
        email,
        firstName,
        lastName,
        password,
        termsOfService,
        personalDataProcessing,
        isSubscribedToMailNotifications,
    }, undefined, true).then(result => {
        if (result.enabled) {
            storeUser(result);
        }

        return result;
    });
}

function confirm(token: string, language: string) {
    return apiService.get(`/register/confirm/${token}?_locale=${language}`);
}

function login(email: string, password: string, language: string) {
    return apiService.post(API.LOGIN + `?_locale=${language}`, {
        email,
        password,
    }, undefined, true).then(
        user => {
            storeUser(user);

            return user;
        }
    );
}

function storeUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
}

function logout(language: string) {
    return apiService.post(API.LOGOUT + `?_locale=${language}`, undefined, undefined, true).then(
        () => localStorage.removeItem('user'),
    );
}

function resetPasswordRequest(email: string, language: string) {
    const resetPasswordUrl = apiConfig.frontendBaseUrl + '/reset-password';

    return apiService.post(`/resetting/send-email?_locale=${language}&confirmationUrl=${encodeURIComponent(resetPasswordUrl)}`, {
        email,
    }, undefined, true);
}

function resetPassword(token: string, password: string, language: string) {
    return apiService.post(`/resetting/reset/${token}?_locale=${language}`, {
        password,
    }, undefined, true);
}

function getUser(userId: string) {
    return apiService.get(`${API.PERSONS}/${userId}`);
}

function getLoggedUser() {
    return apiService.get(API.LOGIN);
}

export default usersService;