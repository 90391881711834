import { Field, FieldProps, getIn } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import {Div} from 'glamorous';
import HelpIcon from "../HelpIcon/HelpIcon";
import {countries} from './countries';
import FormFieldError from "../FormFieldError/FormFieldError";
import {ITaxReturn} from "../../modules/Dashboard/components/TaxReturn/ITaxReturn";

interface CatalogComboProps {
    name: string;
    fieldName: string;
    required?: boolean;
    textKey?: string;
    taxReturn: ITaxReturn;
}

class CountriesComboFieldInner extends React.Component<WithNamespaces & FieldProps & CatalogComboProps> {

    render () {
        const {
            field,
            required,
            textKey,
            t,
            form: { errors, touched },
            taxReturn
        } = this.props;
        const _textKey = textKey ? textKey : field.name;

        const hasHelp = t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''}) !== '';

        return (<Field>
            {({ form }) => (<Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t(_textKey + '.label')}{required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon helpKey={_textKey} taxReturn={taxReturn} t={t} />}
                    </Label>
                    <select {...field} className={field.value ? null : 'empty'}>
                        <option value="">{t(_textKey +  '.placeholder')}</option>
                        {countries.map((item, index) => (
                            <option key={index} value={item.code}>{item.label}</option>
                        ))}
                    </select>
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={_textKey} isValidating={form.isValidating} t={t}/>
            </Div>)}
        </Field>);
    }
}


class CountriesComboField extends React.Component<WithNamespaces & CatalogComboProps> {
    render () {
        const { name, ...rest } = this.props;
        return (
            <Field name={name} fieldName={name} {...rest} component={CountriesComboFieldInner} />
        );
    }
}

const mapStateToProps = (state: any, props) => {
	return {};
};

const mapDispatchToProps = (dispatch: Function, props) => {
	return {};
};

export default translate('translations')(connect(
	mapStateToProps,
	mapDispatchToProps
)(CountriesComboField)) as any;
