import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import ResetPasswordForm from './ResetPasswordForm';
import usersActions from '../../actions/usersActions';

const mapStateToProps = (state: any) => {
	return {
        lang: state.app.lang.lang,
	};
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {
		resetPassword: (password: string, language: string) => {
			const {token} = queryString.parse(props.location.search);
			return dispatch(usersActions.resetPassword(Array.isArray(token) ? token[0] : (token ? token: ''), password, language));
		},
	};
};

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(ResetPasswordForm));