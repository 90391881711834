import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import glamorous, { Div } from 'glamorous';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Row, Col } from 'reactstrap';
import theme from '../../styles/theme';
import constants from '../../utils/constants';
import history from '../../utils/history';
import { trActions } from '../Common/actions/trActions';
import { ITaxReturn } from './components/TaxReturn/ITaxReturn';
import TaxReturn from './components/TaxReturn/TaxReturn';
import AppLayout from "../../layouts/AppLayout";
import HelpIcon from "../../components/HelpIcon/HelpIcon";

interface IDashboardPageProps {
    loggedUser: any;
    getMyTaxReturns: Function;
    taxReturns: ITaxReturn[];
}

class DashboardPage extends React.Component<WithNamespaces & IDashboardPageProps & RouteComponentProps, any> {

    componentDidMount() {
        this.props.getMyTaxReturns(this.props.loggedUser.id);
    }

    componentDidUpdate() {
        if (this.props.taxReturns && this.props.taxReturns.length === 0) {
            history.push(constants.ROUTES.WIZARD.STEP[0]);
        }
    }

    goToCreate() {
        history.push(constants.ROUTES.WIZARD.STEP[0]);
    }

	render() {
		const { t, loggedUser, taxReturns } = this.props;

		if (!taxReturns) {
		    return null;
        }

		return (
		    <AppLayout>
                <h1>{t('dashboard.title.label')}</h1>
                <p>{t('dashboard.title.placeholder')}</p>
                <Row>
                    {taxReturns.map(taxReturn => {
                        return <Col key={taxReturn.id} xs="12" sm="6" md="4"><TaxReturn taxReturn={taxReturn} /></Col>
                    })}
                </Row>
                <StyledButton className="btn-primary" title={t('dashboard.add.label')} onClick={() => this.goToCreate()}>
                    <FontAwesomeIcon icon={faPlus} style={{color: theme.colors.white}} /> {t('dashboard.add.label')}
                </StyledButton>
                <ButtonHelp>
                    <strong>{t('dashboard.add.placeholder')}</strong>{' '}
                    <HelpIcon helpKey="dashboard.add" t={t} />
                </ButtonHelp>
            </AppLayout>
		)
	}
}

const StyledButton = glamorous(Button)({
    marginTop: '24px',
});

const ButtonHelp = glamorous(Div)({
    marginTop: '24px',
});

const mapStateToProps = (state) => {
	return {
        taxReturns: state.entities.taxReturns,
        loggedUser: state.app.auth.user
	};
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {
		getMyTaxReturns: (userId: string) => {
		    return dispatch(trActions.loadMy(userId));
		},
	};
};

export default translate('translations')(withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardPage)) as any);
