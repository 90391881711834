import { Field, FieldProps, getIn } from 'formik';
import { get, set, cloneDeep } from 'lodash';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import {Div} from 'glamorous';
import HelpIcon from '../../../../components/HelpIcon/HelpIcon';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import FormFieldError from "../../../../components/FormFieldError/FormFieldError";
import {ITaxReturn} from "../../../Dashboard/components/TaxReturn/ITaxReturn";

interface IUniCheckboxProps {
    name: string;
    textKey?: string;
    errorTextKey?: string;
    placeholder?: boolean;
    placeholderWhenTrue?: boolean;
    readOnly?: boolean;
    validateForm: Function;
    taxReturn: ITaxReturn;
}

class UniCheckboxInner extends React.Component<WithNamespaces & FieldProps & IUniCheckboxProps> {

    render () {
        const {
            field,
            textKey,
            errorTextKey,
            placeholder,
            placeholderWhenTrue,
            readOnly,
            t,
            form: {errors, touched},
            validateForm,
            taxReturn
        } = this.props;
        const _textKey = textKey ? textKey : field.name;

        const hasHelp = !readOnly && (t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''}) !== '');

        const hasPlaceholder = (placeholderWhenTrue && placeholderWhenTrue && field.value)
            || (!placeholderWhenTrue && placeholder);

        const onChange = (eventOrPath) => {
            field.onChange(eventOrPath);
            setTimeout(validateForm,100);
        };

        return (<Field>
            {({ form }) => (<Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                <FormGroup className="checkbox">
                    <Div>
                        <Input
                            type="checkbox"
                            checked={field.value}
                            id={field.name}
                            {...field}
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                        <Label for={field.name}>
                            {t(_textKey + '.label', { taxReturnYear: taxReturn.year, followingYear: taxReturn.year + 1 } )}{' '}
                        </Label>
                        {hasHelp && <HelpIcon helpKey={_textKey} taxReturn={taxReturn} t={t} />}
                    </Div>
                    {hasPlaceholder && <p>{t(_textKey + '.placeholder')}</p>}
                    <FormFieldError fieldName={field.name} textKey={errorTextKey ? errorTextKey : _textKey} isValidating={form.isValidating} t={t}/>
                </FormGroup>
            </Div>)}
        </Field>);
    }
}

const UniCheckbox: React.SFC<WithNamespaces & IUniCheckboxProps> = ({  name, ...rest }) => {
    return (
        <Field name={name} fieldName={name} {...rest} component={UniCheckboxInner} />
    )
};

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: Function) => {
	return {};
}

export default translate('translations')(connect(
	mapStateToProps,
	mapDispatchToProps
)(UniCheckbox)) as any;
