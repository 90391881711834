import React from 'react';
import { isObject } from 'formik';
import constants from "../../../utils/constants";
import confirm from 'reactstrap-confirm';
import history from '../../../utils/history';

export class WizardService {
    private firstFieldFocused = false;
    private shouldValidateCalled = false;

    constructor(
        private step: number,
        private taxReturnId: string,
        private update: Function,
        private validate: Function,
        private t: Function
    ) {}

    validateAndSave = (values: any) => {
        return this
            .update(this.taxReturnId, values)
            .then((value) => {
                return this.validate(this.taxReturnId, this.step)
                    .then((value) => {
                        return {};
                    });
            });
    };

    backToDashboard() {
        history.push(constants.ROUTES.DASHBOARD)
    }

    previousStep() {
        const url = constants.ROUTES.WIZARD.STEP[this.step - 1] + '/' + this.taxReturnId;
        history.push(url);
    }

    setAllFieldsTouched(errors, setFieldTouched, path = '') {
        Object.keys(errors).forEach(key => {
            const keyPath = path === '' ? key : `${path}.${key}`;
            if (isObject(errors[key])) {
                this.setAllFieldsTouched(errors[key], setFieldTouched, keyPath)
            } else {
                if (!this.firstFieldFocused) {
                    //TODO: solve this some better way ;)
                    if (document.querySelector(`[name="${keyPath}"]`)) {
                        (document.querySelector(`[name="${keyPath}"]`) as HTMLElement).focus();
                        this.firstFieldFocused = true;
                    }
                }
                setFieldTouched(keyPath, true, false);
            }

        })

    }

    nextStep(validateForm, setFieldTouched, t) {
        const url = constants.ROUTES.WIZARD.STEP[this.step + 1] + '/' + this.taxReturnId;

        validateForm()
            .then((errors) => {
                if (Object.keys(errors).length !== 0) {
                    confirm({
                        title: (
                            <h3>{this.t('confirm.label')}</h3>
                        ),
                        message: this.t('confirm.placeholder'),
                    }).then((confirmed) => {
                        if (confirmed) {
                            history.push(url);
                        } else {
                            this.firstFieldFocused = false;
                            this.setAllFieldsTouched(errors, setFieldTouched);
                        }
                    })

                } else {
                    history.push(url);
                }
            })
    }

    goPay(validateForm, setFieldTouched, t, payFn) {
        validateForm()
            .then((errors) => {
                if (Object.keys(errors).length !== 0) {
                    this.firstFieldFocused = false;
                    this.setAllFieldsTouched(errors, setFieldTouched);
                } else {
                    payFn();
                }
            })
    }


    validateAndTouch(validateForm, setFieldTouched) {
        if (this.shouldValidateCalled) {
            return;
        }
        this.shouldValidateCalled = true;
        validateForm()
            .then((errors) => {
                if (Object.keys(errors).length !== 0) {
                    this.firstFieldFocused = false;
                    this.setAllFieldsTouched(errors, setFieldTouched);
                }
            })
    }
}