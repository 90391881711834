export const colors = {
	black: '#000',
	blue: '#2335b7',
	white: '#fff',
	grayVeryLight: '#f8fbfc',
	grayLight: '#eff2f3',
	gray: '#cccccc',
	grayDark: '#5A6169',
	blackLighter: '#212529',
	red: '#db0000',
	green: '#53c482',
	orange: 'orange',
	blueGradient: 'linear-gradient(to right, #2330b6 0%,#1065e1 100%)',
};

export default colors;