import catalogConstants from './catalogConstants';
import { catalogService } from './catalogService';
import { langService } from '../../services/langService';

export const catalogActions = {
    onLoad,
};

function onLoad(catalogName: string, lang: string) {
	return (dispatch: Function) => {
        dispatch({type: catalogConstants.LOAD.REQUEST, catalogName});

        return catalogService.load(catalogName, lang)
			.then(
				response => {
                    const result = {catalogName, response};
                    dispatch({type: catalogConstants.LOAD.SUCCESS, result});
                },
				(error: any) => dispatch({type: catalogConstants.LOAD.FAILURE, error}),
            );
    }
}