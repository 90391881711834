import { Field, getIn, FieldProps } from 'formik';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import HelpIcon from "../../../../../components/HelpIcon/HelpIcon";
import {Div} from 'glamorous';
import FormFieldError from "../../../../../components/FormFieldError/FormFieldError";
import { translate, WithNamespaces } from 'react-i18next';
import {ITaxReturn} from "../../../../Dashboard/components/TaxReturn/ITaxReturn";

interface ExpensesTypeProps {
    name: string;
    textKey?: string;
    required?: boolean;
    value?: any;
    validateForm?: Function;
    setFieldValue?: Function;
    allowRealExpenses?: boolean;
    allowLumpSum?: boolean;
    title?: string;
    disabled?: boolean;
    taxReturn: ITaxReturn;
    main: boolean;
}

class ExpensesTypeInner extends React.Component<WithNamespaces & FieldProps & ExpensesTypeProps> {

    render() {
        const {
            field,
            textKey,
            required,
            t,
            value,
            setFieldValue,
            validateForm,
            allowRealExpenses,
            allowLumpSum,
            title,
            disabled,
            form: { errors, touched },
            taxReturn,
            main,
        } = this.props;
        const _textKey = textKey ? textKey : field.name;

        const hasHelp = t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''}) !== '';

        const onChange = (eventOrPath) => {
            field.onChange(eventOrPath);
            if (eventOrPath.target.value === 'real') {
                setFieldValue(field.name.substring(0, field.name.length - 4), undefined);
            }
            if (main && taxReturn.selfEmployment.otherActivities) {
                taxReturn.selfEmployment.otherActivities.forEach((otherActivity, index) => {
                    if (eventOrPath.target.value === 'real') {
                        setFieldValue(`selfEmployment.otherActivities.${index}.expensesType`, 'real');
                    } else if (otherActivity.expensesType === 'real') {
                        setFieldValue(`selfEmployment.otherActivities.${index}.expensesType`, undefined);
                        setFieldValue(`selfEmployment.otherActivities.${index}.expenses`, undefined);
                    }
                });
            }
            setTimeout(validateForm,100);
        };

        const _allowRealExpenses = allowRealExpenses === undefined ? true : allowRealExpenses;
        const _allowLumpSum = allowLumpSum === undefined ? true : allowLumpSum;

        return (<Field name={field.name} value={value}>
            {({ field, form }) => (
                <Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                    <FormGroup>
                        <Label>
                            {t(_textKey + '.label')}{required ? '*' : ''}{' '}
                            {hasHelp && <HelpIcon helpKey={_textKey} t={t} />}
                        </Label>
                        <select {...field} onChange={onChange} className={field.value ? null : 'empty'} title={title} disabled={disabled}>
                            <option value="">{t(_textKey +  '.placeholder')}</option>
                            {_allowRealExpenses && <option value="real">{t('selfEmployment.expensesTypes.real.label')}</option>}
                            {_allowLumpSum && <><option value="lump_60">{t('selfEmployment.expensesTypes.lump_60.label')}</option>
                                <option value="lump_80">{t('selfEmployment.expensesTypes.lump_80.label')}</option>
                                <option value="lump_40">{t('selfEmployment.expensesTypes.lump_40.label')}</option></>}
                        </select>
                    </FormGroup>
                    <FormFieldError fieldName={field.name} textKey={_textKey} isValidating={form.isValidating} t={t}/>
                </Div>)}
        </Field>);

    }
}

class ExpensesType extends React.Component<WithNamespaces & ExpensesTypeProps> {

    render () {
        const { name, ...rest } = this.props;
        return (
            <Field name={name} fieldName={name} {...rest} component={ExpensesTypeInner} />
        );
    }
}
export default translate('translations')(ExpensesType) as any;
