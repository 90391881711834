import { Field, getIn } from 'formik';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import HelpIcon from "../../../../../components/HelpIcon/HelpIcon";
import {Div} from 'glamorous';
import FormFieldError from "../../../../../components/FormFieldError/FormFieldError";

export const OTHER_INCOME_TYPES = {
    OCCASIONAL: 'A',
    SALE_REAL_ESTATE: 'B',
    SALE_OF_MOVABLE: 'C',
    SALE_SHARES: 'D',
    OTHER: 'F'
};

export default function OtherIncomeType(props) {
    const { name, textKey, value, t, required } = props;
    const hasHelp = t(textKey + '.helpText', {defaultValue: ''}) !== ''
        || t(textKey + '.helpImage', {defaultValue: ''}) ;

    return (<Field name={name} value={value}>
        {({ field, form }) => (
            <Div className={!form.isValidating && getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t(textKey + '.label')}{required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon helpKey={textKey} t={t} />}
                    </Label>
                    <select {...field} className={field.value ? null : 'empty'}>
                        <option value="">{t('otherIncomes.type.placeholder')}</option>
                        <option value={OTHER_INCOME_TYPES.OCCASIONAL}>{t('otherIncomes.types.occasional.label')}</option>
                        <option value={OTHER_INCOME_TYPES.SALE_REAL_ESTATE}>{t('otherIncomes.types.saleOfRealEstate.label')}</option>
                        <option value={OTHER_INCOME_TYPES.SALE_OF_MOVABLE}>{t('otherIncomes.types.saleOfMovableAssets.label')}</option>
                        <option value={OTHER_INCOME_TYPES.SALE_SHARES}>{t('otherIncomes.types.saleOfShares.label')}</option>
                        <option value={OTHER_INCOME_TYPES.OTHER}>{t('otherIncomes.types.other.label')}</option>
                    </select>
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={textKey} isValidating={form.isValidating} t={t}/>
            </Div>)}
    </Field>);
}
