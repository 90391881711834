import React from 'react';
import glamorous,{Div} from 'glamorous';
import {Col, Row, Container} from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import constants from '../../../../../utils/constants';

interface IEmploymentsSummaryProps {
    employments: Array<any>;
    taxReturnId: string;
    thousandSeparator: string;
    czkSuffix: string;
    t: Function;
    year: number;
}

export default class EmploymentsSummary extends React.Component<IEmploymentsSummaryProps> {
	render() {
        const { employments, taxReturnId, t, thousandSeparator, czkSuffix, year } = this.props;

        const showPaidAdvances = employments.filter((employment) => employment.paidAdvances).length > 0;
        const showWitholdingTax = employments.filter((employment) => employment.witholdingTax).length > 0;
        const showTaxBonus = employments.filter((employment) => employment.taxBonus).length > 0;
        const colWidth = showPaidAdvances && showWitholdingTax && showTaxBonus ? 2 : 3;

        return (<Div className="formSection summary">
            <h3 className="noCheckbox">{t('sections.employments.label')}</h3>
            <Div className="subForm">
                <Div className="d-none d-md-block d-lg-block">
                    <Row>
                        <Col md="3" lg="3">
                            {t('employments.grossIncome.label')}
                        </Col>
                        {year < 2021 && <Col md="3" lg="3">
                            {t('employments.paidInsurance.label')}
                        </Col>}
                        {showPaidAdvances && <Col md={colWidth} lg={colWidth}>
                            {t('employments.paidAdvances.label')}
                        </Col>}
                        {showWitholdingTax && <Col md={colWidth} lg={colWidth}>
                            {t('employments.witholdingTax.label')}
                        </Col>}
                        {showTaxBonus && <Col md={colWidth} lg={colWidth}>
                            {t('employments.taxBonus.label')}
                        </Col>}
                    </Row>
                    {employments.map((employment, index) => (
                        <Row key={index}>
                            <Col md="3" lg="3">
                                <strong><NumberFormat value={employments[index].grossIncome} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                            {year < 2021 && <Col md="3" lg="3">
                                <strong><NumberFormat value={employments[index].paidInsurance} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>}
                            {showPaidAdvances && <Col md={colWidth} lg={colWidth}>
                                <strong><NumberFormat value={employments[index].paidAdvances} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>}
                            {showWitholdingTax && <Col md={colWidth} lg={colWidth}>
                                <strong><NumberFormat value={employments[index].witholdingTax} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>}
                            {showTaxBonus && <Col md={colWidth} lg={colWidth}>
                                <strong><NumberFormat value={employments[index].taxBonus} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>}
                        </Row>
                    ))}
                </Div>
                <Div className="d-block d-md-none d-lg-none">
                    {employments.map((employment, index) => (<Div className="summary-block">
                        <Row key={index}>
                            <Col xs="6">
                                {t('employments.grossIncome.label')}
                            </Col>
                            <Col xs="6">
                                <strong><NumberFormat value={employments[index].grossIncome} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </Row>
                        {year < 2021 && <Row>
                            <Col xs="6">
                                {t('employments.paidInsurance.label')}
                            </Col>
                            <Col xs="6">
                                <strong><NumberFormat value={employments[index].paidInsurance} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </Row>}
                        {showPaidAdvances && <Row>
                            <Col xs="6">
                                {t('employments.paidAdvances.label')}
                            </Col>
                            <Col xs="6">
                                <strong><NumberFormat value={employments[index].paidAdvances} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </Row>}
                        {showWitholdingTax && <Row>
                            <Col xs="6">
                                {t('employments.witholdingTax.label')}
                            </Col>
                            <Col xs="6">
                                <strong><NumberFormat value={employments[index].witholdingTax} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </Row>}
                        {showTaxBonus && <Row>
                            <Col xs="6">
                                {t('employments.taxBonus.label')}
                            </Col>
                            <Col xs="6">
                                <strong><NumberFormat value={employments[index].taxBonus} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </Row>}
                    </Div>))}
                </Div>
                <Link to={constants.ROUTES.WIZARD.STEP[1] + '/' + taxReturnId}>{t('editThisSection.label')}</Link>
            </Div>
        </Div>)
	}
}

