import React from 'react';

interface ValidationWrapperProps {
	wizardService: any;
	validateForm: Function;
	setFieldTouched: Function;
}

class ValidationWrapper extends React.Component<ValidationWrapperProps> {

	componentWillMount() {
        this.props.wizardService.validateAndTouch(this.props.validateForm, this.props.setFieldTouched);
	}

	render() {
		return null;
	}
}

export default ValidationWrapper;
