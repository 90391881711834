import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import usersActions from '../../actions/usersActions';
import LoginForm from './LoginForm';

export interface IStateProps {
    lang: string;
}

export interface IDispatchProps {
	login: Function;
}

const mapStateToProps = (state: any) => {
	return {
        lang: state.app.lang.lang,
	};
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {
		login: (email: string, password: string, language: string) => {
			return dispatch(usersActions.login(email, password, language))
		}
	};
};

export default withRouter(connect<IStateProps, IDispatchProps, RouteComponentProps<any>>(
	mapStateToProps,
	mapDispatchToProps
)(LoginForm));