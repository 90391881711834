import { Field, getIn } from 'formik';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import HelpIcon from "../../../../../components/HelpIcon/HelpIcon";
import {Div} from 'glamorous';
import FormFieldError from "../../../../../components/FormFieldError/FormFieldError";

export default function SideActivityReason(props) {
    const { name, value, t, required } = props;
    const hasHelp = t(name + '.helpText', {defaultValue: ''}) !== ''
        || t(name + '.helpImage', {defaultValue: ''}) ;

    return (<Field name={name} value={value}>
        {({ field, form }) => (
            <Div className={!form.isValidating && getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t('healthInsurance.vzpBranch.label')}{required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon helpKey={name} t={t} />}
                    </Label>
                    <select {...field} className={field.value ? null : 'empty'}>
                        <option value="">{t('healthInsurance.vzpBranch.placeholder')}</option>
                        <option value="PRAGUE">{t('healthInsurance.vzpBranches.PRAGUE.label')}</option>
                        <option value="SOUTH_BOHEMIA">{t('healthInsurance.vzpBranches.SOUTH_BOHEMIA.label')}</option>
                        <option value="WEST_BOHEMIA">{t('healthInsurance.vzpBranches.WEST_BOHEMIA.label')}</option>
                        <option value="NORTH_BOHEMIA">{t('healthInsurance.vzpBranches.NORTH_BOHEMIA.label')}</option>
                        <option value="EAST_BOHEMIA">{t('healthInsurance.vzpBranches.EAST_BOHEMIA.label')}</option>
                        <option value="SOUTH_MORAVIA">{t('healthInsurance.vzpBranches.SOUTH_MORAVIA.label')}</option>
                        <option value="NORTH_MORAVIA">{t('healthInsurance.vzpBranches.NORTH_MORAVIA.label')}</option>
                        <option value="KARLOVY_VARY">{t('healthInsurance.vzpBranches.KARLOVY_VARY.label')}</option>
                        <option value="SILESIA">{t('healthInsurance.vzpBranches.SILESIA.label')}</option>
                    </select>
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={name} isValidating={form.isValidating} t={t}/>
            </Div>)}
    </Field>);
}