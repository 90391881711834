import React from 'react';
import {Div} from 'glamorous';
import {Col, Row } from 'reactstrap';
import {ITaxReturn} from "../../../../Dashboard/components/TaxReturn/ITaxReturn";
import UniField from '../../UniFields/UniField';
import UniRadio from '../../UniFields/UniRadio';
import CatalogComboField from "../../../../../components/CatalogComboField/CatalogComboField";
import CountriesComboField from "../../../../../components/CatalogComboField/CountriesComboField";

interface IAccountFormProps {
    name: string;
    accountValue: any;
    required: boolean;
    whole: boolean;
    validateForm: Function;
    taxReturn: ITaxReturn;
    setFieldValue: Function;
    setFieldTouched: Function;
    t: Function;
}

export default class AccountForm extends React.Component<IAccountFormProps> {
	render() {
        const { name, accountValue, required, whole, taxReturn, validateForm, setFieldValue, setFieldTouched, t } = this.props;

        return (<Div>
            <Row>
                <Col xs="12" sm="6" lg="4">
                    <UniRadio
                        name={name + '.accountType'}
                        validateForm={validateForm}
                        textKey="account.accountType.domestic"
                        value="DOMESTIC"
                        required={required}
                        showsError={true}
                        taxReturn={taxReturn}
                    />
                </Col>
                <Col xs="12" sm="6" lg="4">
                    <UniRadio
                        name={name + '.accountType'}
                        validateForm={validateForm}
                        textKey="account.accountType.foreign"
                        value="FOREIGN"
                        required={required}
                        taxReturn={taxReturn}
                    />
                </Col>
            </Row>
            {accountValue.accountType === 'DOMESTIC' && <Row>
                <Col xs="12" sm="3" lg="4">
                    <UniField name={name + '.domesticAccount.prefix'} textKey="account.domesticAccount.prefix" taxReturn={taxReturn} />
                </Col>
                <Col xs="12" sm="6" lg="4">
                    <UniField name={name + '.domesticAccount.number'} textKey="account.domesticAccount.number" required={required && accountValue.accountType === 'DOMESTIC'} taxReturn={taxReturn} />
                </Col>
                <Col xs="12" sm="3" lg={whole ? 2 : 4 }>
                    <UniField name={name + '.domesticAccount.code'} textKey="account.domesticAccount.code" required={required && accountValue.accountType === 'DOMESTIC'} taxReturn={taxReturn} />
                </Col>
                {whole && <Col xs="12" sm="3" lg="2">
                    <CatalogComboField
                        catalogName="currency"
                        name={name + '.domesticAccount.currency'}
                        textKey="account.domesticAccount.currency"
                        required={required && accountValue.accountType === 'DOMESTIC'}
                        validateForm={validateForm}
                        taxReturn={taxReturn}
                    />
                </Col>}
                {whole && <Col xs="12" sm="4" lg="5">
                    <UniField
                        name={name + '.domesticAccount.bankName'}
                        textKey="account.domesticAccount.bankName"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        required={required && accountValue.accountType === 'DOMESTIC'}
                        taxReturn={taxReturn}
                    />
                </Col>}
                {whole && <Col xs="12" sm="5" lg="5">
                    <UniField name={name + '.domesticAccount.accountHolder'} textKey="account.domesticAccount.accountHolder" required={required && accountValue.accountType === 'DOMESTIC'} taxReturn={taxReturn} />
                </Col>}
            </Row>}
            {accountValue.accountType === 'FOREIGN' && <p>{t('account.accountType.foreign.placeholder')}</p>}
            {accountValue.accountType === 'FOREIGN' && <Div>
                <Row>
                    <Col xs="12" sm="7" lg="6">
                        <UniField name={name + '.foreignAccount.iban'} textKey="account.foreignAccount.iban" required={required && accountValue.accountType === 'FOREIGN'} taxReturn={taxReturn} />
                    </Col>
                    <Col xs="12" sm="5" lg={whole ? 4 : 6}>
                        <UniField name={name + '.foreignAccount.swift'} textKey="account.foreignAccount.swift" required={required && accountValue.accountType === 'FOREIGN'} taxReturn={taxReturn} />
                    </Col>
                    {whole && <Col xs="12" sm="6" lg="2">
                        <CatalogComboField
                            catalogName="currency"
                            name={name + '.foreignAccount.currency'}
                            textKey="account.foreignAccount.currency"
                            required={required && accountValue.accountType === 'FOREIGN'}
                            validateForm={validateForm}
                            taxReturn={taxReturn}
                        />
                    </Col>}
                </Row>
                {whole && <Row>
                    <Col xs="12" sm="6" lg="4">
                        <UniField name={name + '.foreignAccount.bankName'} textKey="account.foreignAccount.bankName" required={required && accountValue.accountType === 'FOREIGN'} taxReturn={taxReturn} />
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                        <UniField name={name + '.foreignAccount.bankAddress.street'} textKey="account.foreignAccount.bankAddress.street" required={required && accountValue.accountType === 'FOREIGN'} taxReturn={taxReturn} />
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                        <UniField name={name + '.foreignAccount.bankAddress.city'} textKey="account.foreignAccount.bankAddress.city" required={required && accountValue.accountType === 'FOREIGN'} taxReturn={taxReturn} />
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                        <UniField
                            name={name + '.foreignAccount.bankAddress.zipCode'}
                            textKey="account.foreignAccount.bankAddress.zipCode"
                            errorTextKey="address.zipCode"
                            taxReturn={taxReturn}
                        />
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                        <CountriesComboField
                            name={name + '.foreignAccount.bankAddress.country'}
                            textKey="account.foreignAccount.bankAddress.country"
                            required={required && accountValue.accountType === 'FOREIGN'}
                            taxReturn={taxReturn}
                        />
                    </Col>
                </Row>}
                {whole && <Row>
                    <Col xs="12" sm="6" lg="4">
                        <UniField name={name + '.foreignAccount.accountHolderNameAndAddress'} textKey="account.foreignAccount.accountHolderNameAndAddress" required={required && accountValue.accountType === 'FOREIGN'} taxReturn={taxReturn} />
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                        <UniField name={name + '.foreignAccount.accountHolderAddress.street'} textKey="account.foreignAccount.accountHolderAddress.street" required={required && accountValue.accountType === 'FOREIGN'} taxReturn={taxReturn} />
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                        <UniField name={name + '.foreignAccount.accountHolderAddress.city'} textKey="account.foreignAccount.accountHolderAddress.city" required={required && accountValue.accountType === 'FOREIGN'} taxReturn={taxReturn} />
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                        <UniField
                            name={name + '.foreignAccount.accountHolderAddress.zipCode'}
                            textKey="account.foreignAccount.accountHolderAddress.zipCode"
                            errorTextKey="address.zipCode"
                            taxReturn={taxReturn}
                        />
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                        <CountriesComboField
                            name={name + '.foreignAccount.accountHolderAddress.country'}
                            textKey="account.foreignAccount.accountHolderAddress.country"
                            required={required && accountValue.accountType === 'FOREIGN'}
                            taxReturn={taxReturn}
                        />
                    </Col>
                </Row>}
            </Div>}
        </Div>);
	}
}
