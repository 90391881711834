import { combineReducers } from 'redux';

import { taxReturn, taxReturns, newTaxReturn } from './entities/taxReturn';
import { catalogs } from './entities/catalog';
import { xmlAttachments } from './entities/xmlAttachments';
import { certifiedCheckAttachments } from './entities/certifiedCheckAttachments';
import { onMyBehalfAttachments } from './entities/onMyBehalfAttachments';

export const entitiesReducer =  combineReducers({
    taxReturn,
    taxReturns,
    newTaxReturn,
    catalogs,
    xmlAttachments,
    certifiedCheckAttachments,
    onMyBehalfAttachments
});
export default entitiesReducer;