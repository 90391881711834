import trConstants from '../../modules/Common/actions/trConstants';
import createReducer from '../../store/createReducer';
import wizardConstants from '../../modules/Wizard/actions/wizardConstants';

const initialState = {
	isSaving: false,
	errors: null,
	step: null
};

const wizardReducer = createReducer(initialState, {
    [trConstants.PATCH.REQUEST]: savingStart,
    [trConstants.PATCH.SUCCESS]: savingEnd,
	[trConstants.PATCH.FAILURE]: savingEnd,
	[trConstants.PATCH.FAILURE]: processErrors,
	[wizardConstants.SET_PAGE.SUCCESS]: changeWizardStep
});

function savingStart(state, action) {
	return {
		isSaving: true,
		errors: null
	};
}

function savingEnd(state, action) {
	return {
		isSaving: false
	};
}

function processErrors(state, action) {
	return {

	};
}

function changeWizardStep(state, action: any) {
	return {
		step: action.newStep
	};
}


export default wizardReducer;