import { createStore, applyMiddleware } from 'redux';

import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import asyncDispatchMiddleware from './asyncDispatchMiddleware';
import rootReducer from '../reducers/rootReducer';
import constants from '../utils/constants';

const middlewares = [
	thunkMiddleware,
	asyncDispatchMiddleware,
];

if (process.env.REACT_APP_MODE === constants.MODE_DEVELOPMENT) {
	middlewares.push(createLogger());
}

export const store = createStore(
	rootReducer,
	applyMiddleware(...middlewares)
);

export default store;