import React from 'react';
import glamorous, { Div } from 'glamorous';
import { Button, Row } from 'reactstrap';
import UniFile from '../../modules/Wizard/components/UniFields/UniFile';
import { faSpinner, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  { addMessage, messagesConstants } from '../../services/flashMessages';
import { ITaxReturn } from "../../modules/Dashboard/components/TaxReturn/ITaxReturn";
import theme from "../../styles/theme";
import confirm from 'reactstrap-confirm';
import apiConfig from "../../config/apiConfig";
import axios from 'axios';
import fileDownload from 'js-file-download';

interface IAttachmentsProps {
  attachments: any;
  taxReturn: ITaxReturn;
  attachFile: Function;
  deleteAttachment: Function;
  t: Function;
}

interface IAttachmentsState {
  downloading: string;
}

const NoDownloading = 'none';

class Attachments extends React.Component<IAttachmentsProps, IAttachmentsState> {

  readonly state = {
    downloading: NoDownloading,
  };

  createAttachFileFn = (type, totalAttachmentSize, fileNames) => {
    const allowedMimeTypes = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/rtf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf',
      'image/jpeg',
      'text/plain',
      'text/csv',
    ];
    const maxAllowedTotalSize = 5 * 1024 * 1024; // 5 MB
    const {t} = this.props;

    return (file) => {
      if (allowedMimeTypes.indexOf(file.type) === -1 )  {
        addMessage(t('attachments.notSupportedType.label'), messagesConstants.TYPE_ERROR);
        return;
      }
      if (totalAttachmentSize + file.size > maxAllowedTotalSize) {
        addMessage(t('attachments.maxSizeExceeded.label'), messagesConstants.TYPE_ERROR);
        return;
      }
      if (fileNames.includes(file.name)) {
        addMessage(t('attachments.uniqueName.label'), messagesConstants.TYPE_ERROR);
        return;
      }
      this.props.attachFile(this.props.taxReturn.id, type, file);
    };
  };

  deleteAttachment = (id) => {
    const {t, deleteAttachment, taxReturn} = this.props;
    confirm({
      title: (
        <h3>{t('removeAttachmentConfirm.label')}</h3>
      ),
      message: t('removeAttachmentConfirm.placeholder'),
    }).then((confirmed) => {
      if (confirmed) {
        deleteAttachment(taxReturn.id, id);
      }
    });
  };

  downloadAttachment = (file) => {
    if (this.state.downloading !== NoDownloading) {
      return;
    }

    this.setState((oldState) => ({
      ...oldState,
      downloading: file.id,
    }));
    axios.get(
      `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/attachments/${file.id}/download`,
      { responseType: 'blob', withCredentials: true }
    ).then((response) => {
      this.setState((oldState) => ({
        ...oldState,
        downloading: NoDownloading
      }));
      fileDownload(response.data, file.file.name);
    });
  };

  render() {
    const { attachments, t } = this.props;
    const attachmentTypes = Object.keys(attachments);

    let totalAttachmentsSize = 0;
    const fileNames = [];
    const totalAttachments = attachmentTypes.reduce((sum, curr) => {
      if (attachments[curr].files) {
        totalAttachmentsSize += attachments[curr].files.reduce((total, file) => {
          fileNames.push(file.file.name);
          return total + file.file.size;
        }, 0);
      }
      return sum + attachments[curr].count;
    }, 0);

    return <Div>
      {attachmentTypes.map((type) => (<Div key={type}>
        {attachments[type].count > 0 && <Div className="subForm">
          {[...Array(attachments[type].count)].map((item, index) => (<Row key={index}>
            {typeof attachments[type].files[index] === 'undefined' && <UniFile
                type="file"
                attachFile={this.createAttachFileFn(type, totalAttachmentsSize, fileNames)}
                name={`attachments.${type}.files.${index}`}
                textKey={`attachments.${type}`}
                required={attachments[type].required}
            />}
            {typeof attachments[type].files[index] !== 'undefined' && <Div>
                <StyledButton className="btn-primary download" title={attachments[type].files[index].file.name} onClick={() => this.downloadAttachment(attachments[type].files[index])}>
                  {this.state.downloading === attachments[type].files[index].id && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                  {this.state.downloading !== attachments[type].files[index].id && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                  {' '}{attachments[type].files[index].file.name} ({Math.round(attachments[type].files[index].file.size / 1024)} kB)
                </StyledButton>
                <TrashDiv onClick={() => this.deleteAttachment(attachments[type].files[index].id)}>
                    <FontAwesomeIcon icon={faTrash} style={{color: theme.colors.grayDark}} />
                </TrashDiv>
            </Div>}
          </Row>))}
        </Div>}
      </Div>))}
    </Div>
  }
}

const StyledButton = glamorous(Button)({
  marginBottom: '24px',
  fontSize: '16px !important',
});

const TrashDiv = glamorous(Div)({
  display: 'inline-block',
  verticalAlign: 'top',
  cursor: 'pointer',
  marginLeft: '10px',
});

export default Attachments;
