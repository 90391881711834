import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import theme from '../../styles/theme';
import constants from '../../utils/constants';
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher';
import glamorous, { Div, Img } from 'glamorous';

interface IHeaderProps {
	logout: Function;
	userName: string;
	lang: string;
}

interface IHeaderState {
	isOpened: boolean;
}

class HeaderInner extends React.Component<WithNamespaces & IHeaderProps, IHeaderState> {

	readonly state = {
		isOpened: false
	};

    toggle = () => {
        this.setState({
            isOpened: !this.state.isOpened,
        });
    };

	componentDidUpdate() {
		if (!this.props.userName) {
		    this.props.logout(this.props.lang);
        }
	}

	render() {
		const { t, logout, lang, userName } = this.props;

		return (
			<StyledNavbar color="light" light expand="md">
				<NavbarBrand tag="div">
					<NavbarBrandItem>
                        <NavLink href={constants.ROUTES.DASHBOARD}>
                            <BrandImg src={'/logo-white.svg'} />
						</NavLink>
					</NavbarBrandItem>
					<NavbarBrandItem>
                        <LanguageSwitcher />
					</NavbarBrandItem>
				</NavbarBrand>
				<NavbarToggler onClick={this.toggle} />
				<Collapse isOpen={this.state.isOpened} navbar>
					<Nav className="ml-auto" navbar>
						<NavItem>
							<StyledNavLink href={constants.ROUTES.DASHBOARD}>
								<UserImg src={'/user.svg'} /> {userName}
							</StyledNavLink>
						</NavItem>
						<NavItem>
							<StyledNavLink href={"#"} onClick={() => logout(lang)}>
								{t('logout.label')}
							</StyledNavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</StyledNavbar>
		)
	}
}

const StyledNavbar = glamorous(Navbar)({
	background: theme.colors.blueGradient,
    color: theme.colors.white,
});

const StyledNavLink = glamorous(NavLink)({
	color: theme.colors.white + ' !important'
});

const NavbarBrandItem = glamorous(Div)({
	display: 'inline-block',
	marginRight: '12px',
});

const BrandImg = glamorous(Img)({
	height: '32px',
	['@media (max-width: 490px)']: {
		height: '20px',
	},
	['@media (max-width: 400px)']: {
		height: '16px',
	},
	['@media (max-width: 360px)']: {
		height: '12px',
	},
});

const UserImg = glamorous(Img)({
	height: '20px',
});

export default translate('translations')(HeaderInner);
