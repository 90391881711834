import { connect } from 'react-redux';
import SavingIndicatorInner from './SavingIndicatorInner';

const mapStateToProps = (state) => {
	return {
		zIndex: 999,
        isSaving: state.app.wizard.isSaving,
	};
};

const mapDispatchToProps = (dispatch: Function, props: any) => {
	return {

	};
};

const SavingIndicator = connect(
	mapStateToProps,
	mapDispatchToProps
)(SavingIndicatorInner);

export default SavingIndicator;