import { Field, FieldProps, getIn } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { catalogActions } from './catalogActions';
import { ICatalogItem, ITaxOfficeCatalogItem } from './interfaces';
import { langService } from '../../services/langService';
import {Div} from 'glamorous';
import HelpIcon from "../HelpIcon/HelpIcon";
import FormFieldError from "../FormFieldError/FormFieldError";
import {ITaxReturn} from "../../modules/Dashboard/components/TaxReturn/ITaxReturn";

interface CatalogComboProps {
    loadCatalogItems(name: string, lang: string);
    name: string;
    fieldName: string;
    textKey?: string;
    required?: boolean;
    otherOptionName?: string;
    otherOptionValue?: string;
    catalogs: ICatalogItem[];
    catalogName: string; // name of catalog
    taxOfficeCode: string;
    lang: string;
    taxReturn: ITaxReturn;
}

class RegionalOfficesComboFieldInner extends React.Component<WithNamespaces & FieldProps & CatalogComboProps> {

    render () {
        const {
            field,
            textKey,
            required,
            otherOptionName,
            otherOptionValue,
            catalogs,
            catalogName,
            taxOfficeCode,
            t,
            form: { errors, touched },
            taxReturn
        } = this.props;
        const _textKey = textKey ? textKey : field.name;

        const hasHelp = t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''}) !== '';

        let items = [];
        (catalogs && catalogs[catalogName] || []).map(taxOffice => {
            if (taxOffice.code === taxOfficeCode) {
                items = taxOffice.regionalOffices.sort((first, second) => {
                    if (first.label === second.label) {
                        return 0;
                    }
                    const firstNumericPart = first.label.match(/\d+$/);
                    const secondNumericPart = second.label.match(/\d+$/);
                    if (firstNumericPart && secondNumericPart) {
                        if (parseInt(firstNumericPart[0]) < parseInt(secondNumericPart[0])) {
                            return -1;
                        } else {
                            return 1;
                        }
                    } else if (firstNumericPart) {
                        return -1;
                    } else if (secondNumericPart) {
                        return 1;
                    } else {
                        if (first.label < second.label) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                });
            }
        });

        return (<Field>
            {({ form }) => (<Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t(_textKey + '.label')}{required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon helpKey={_textKey} taxReturn={taxReturn} t={t} />}
                    </Label>
                    <select {...field} className={field.value ? null : 'empty'}>
                        <option value="">{t(_textKey +  '.placeholder')}</option>
                        {items.map((item, index) => (
                            <option key={index} value={item.code}>{item.label}</option>
                        ))}
                        {otherOptionName && <option value={otherOptionValue}>{t(otherOptionName +  '.label')}</option>}
                    </select>
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={_textKey} isValidating={form.isValidating} t={t}/>
            </Div>)}
        </Field>);
    }
}


class RegionalOfficesComboField extends React.Component<WithNamespaces & CatalogComboProps> {

    private langs = {};

    constructor(props, s) {
        super(props);
        this.state = {
            langs: {},
        };
    }

	componentWillMount() {
		this.props.loadCatalogItems(this.props.catalogName, langService.getSavedLang());
    }

    componentDidUpdate() {
        if (this.langs[this.props.catalogName] && this.langs[this.props.catalogName] !== this.props.lang) {
            this.props.loadCatalogItems(this.props.catalogName, this.props.lang);
        }
        this.langs[this.props.catalogName] = this.props.lang;
    }

    render () {
        const { name, ...rest } = this.props;
        return (
            <Field name={name} fieldName={name} {...rest} component={RegionalOfficesComboFieldInner} />
        );
    }
}

const mapStateToProps = (state: any, props) => {
	return {
        catalogs: state.entities.catalogs,
        lang: state.app.lang.lang
    };
};

const mapDispatchToProps = (dispatch: Function, props) => {
	return {
        loadCatalogItems: (id: string, lang: string) => {
			return dispatch(catalogActions.onLoad(id, lang));
		}
    };
};

export default translate('translations')(connect(
	mapStateToProps,
	mapDispatchToProps
)(RegionalOfficesComboField)) as any;
