import { faPlusCircle, faTimesCircle, faHome, faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, Form, Formik } from 'formik';
import glamorous, {Div, Span} from 'glamorous';
import { get } from 'lodash';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import {Button, Col, Row } from 'reactstrap';
import NumberOfMonths from '../UniFields/NumberOfMonths';
import UniCheckbox from '../UniFields/UniCheckbox';
import UniField from '../UniFields/UniField';
import { IStepProps } from '../../interfaces/IStepProps';
import theme from "../../../../styles/theme";
import { WizardService } from "../../services/WizardService";
import ValidationWrapper from "../../../../components/ValidationWrapper/ValidationWrapper";

class StepThreeForm extends React.Component<WithNamespaces & IStepProps> {

	render() {
        const { t, taxReturn, update, validate, shouldValidate } = this.props;

        if (!taxReturn.year) {
            return null;
        }

        const wizardService = new WizardService(3, taxReturn.id, update, validate, t);
        const euCountries = [
            'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU',
            'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE', 'UK', 'UA',
        ];

        const canDeduct = () => {
            return !taxReturn.sections.nonCzTaxResident ||
                euCountries.indexOf(taxReturn.nonCzTaxResident.country) !== -1;
        };

        return (
            <Formik
                initialValues={taxReturn}
                onSubmit={values => console.log(values)}
                validateOnChange={false}
                validate={wizardService.validateAndSave}
            >
                {({values, validateForm, setFieldTouched}) => (
                    <Div>
                        <Div className="navButtons">
                            <Button title={t('buttons.backToDashboard.label')} onClick={() => wizardService.backToDashboard()}>
                                <FontAwesomeIcon icon={faHome} />
                            </Button>
                            <Button title={t('buttons.previous.label')} onClick={() => wizardService.previousStep()}>
                                <FontAwesomeIcon icon={faArrowCircleLeft} />
                            </Button>
                            <Button title={t('buttons.next.label')} className="btn-primary" onClick={() => wizardService.nextStep(validateForm, setFieldTouched, t)}>
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                            </Button>
                        </Div>
                        <h1>{taxReturn.personalData.firstName} {taxReturn.personalData.lastName} {taxReturn.year}</h1>
                        <p>{t('wizard.subtitle.label')}</p>
                        <Form>
                            {shouldValidate && <ValidationWrapper wizardService={wizardService} validateForm={validateForm} setFieldTouched={setFieldTouched}/>}
                            {canDeduct() && <Div>
                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.donation"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.donation && <Div className="subForm">
                                        <Row>
                                            <Col xs="12" sm="6" lg="4">
                                                <UniField name="deductibles.donation" required={values.sections.donation} taxReturn={taxReturn} />
                                            </Col>
                                        </Row>
                                    </Div>}
                                </Div>

                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.mortgage"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.mortgage && <Div className="subForm">
                                        <h3>
                                            <UniCheckbox
                                                name="sections.mortgageRent"
                                                validateForm={validateForm}
                                                placeholder={true}
                                                taxReturn={taxReturn}
                                            />
                                        </h3>
                                        {!values.sections.mortgageRent && <h3>
                                            <UniCheckbox
                                                name="deductibles.mortgage.after2021"
                                                validateForm={validateForm}
                                                placeholder={true}
                                                taxReturn={taxReturn}
                                            />
                                        </h3>}
                                        {!values.sections.mortgageRent && <Row>
                                            <Col xs="12" sm="6" lg="4">
                                                <UniField
                                                    name="deductibles.mortgage.interests"
                                                    required={values.sections.mortgage && !values.sections.mortgageRent}
                                                    taxReturn={taxReturn}
                                                />
                                            </Col>
                                            <Col xs="12" sm="6" lg="4">
                                                <NumberOfMonths
                                                    name="deductibles.mortgage.numberOfMonths"
                                                    value={values.deductibles.mortgage.numberOfMonths}
                                                    required={values.sections.mortgage && !values.sections.mortgageRent}
                                                    validateForm={validateForm}
                                                    taxReturn={taxReturn}
                                                />
                                            </Col>
                                        </Row>}
                                    </Div>}
                                </Div>

                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.pensionInsurance"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.pensionInsurance && <Div className="subForm">
                                        <Row>
                                            <Col xs="12" sm="6" lg="4">
                                                <UniField name="deductibles.pensionInsurance.contracts" required={values.sections.pensionInsurance} taxReturn={taxReturn} />
                                            </Col>
                                            <Col xs="12" sm="6" lg="4">
                                                <UniField name="deductibles.pensionInsurance.amount" required={values.sections.pensionInsurance} taxReturn={taxReturn} />
                                            </Col>
                                        </Row>
                                    </Div>}
                                </Div>

                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.lifeInsurance"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.lifeInsurance && <Div className="subForm">
                                        <Row>
                                            <Col xs="12" sm="6" lg="4">
                                                <UniField name="deductibles.lifeInsurance.contracts" required={values.sections.lifeInsurance} taxReturn={taxReturn} />
                                            </Col>
                                            <Col xs="12" sm="6" lg="4">
                                                <UniField name="deductibles.lifeInsurance.amount" required={values.sections.lifeInsurance} taxReturn={taxReturn} />
                                            </Col>
                                        </Row>
                                    </Div>}
                                </Div>

                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.married"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.married && <Div className="subForm">
                                        <h3>
                                            <UniCheckbox
                                                name="sections.commonHousehold"
                                                validateForm={validateForm}
                                                placeholder={true}
                                                taxReturn={taxReturn}
                                            />
                                        </h3>
                                        <h3>
                                            <UniCheckbox
                                                name="sections.partners"
                                                validateForm={validateForm}
                                                placeholder={true}
                                                taxReturn={taxReturn}
                                            />
                                        </h3>
                                        {values.sections.commonHousehold && values.sections.partners && <Div>
                                            <Row>
                                                <Col xs="12" sm="6" lg="4">
                                                    <UniField name="deductibles.marriage.spouseFirstName" required={values.sections.married && values.sections.commonHousehold} taxReturn={taxReturn} />
                                                </Col>
                                                <Col xs="12" sm="6" lg="4">
                                                    <UniField name="deductibles.marriage.spouseLastName" required={values.sections.married && values.sections.commonHousehold} taxReturn={taxReturn} />
                                                </Col>
                                                <Col xs="12" sm="6" lg="4">
                                                    <UniField name="deductibles.marriage.birthNumber" required={values.sections.married && values.sections.commonHousehold} taxReturn={taxReturn} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="6" lg="4">
                                                    <NumberOfMonths
                                                        name="deductibles.marriage.numberOfMonths"
                                                        value={values.deductibles.marriage.numberOfMonths}
                                                        required={values.sections.married && values.sections.commonHousehold}
                                                        validateForm={validateForm}
                                                        taxReturn={taxReturn}
                                                    />
                                                </Col>
                                                <Col xs="12" sm="6" lg="4">
                                                    <UniCheckbox
                                                        name="deductibles.marriage.ztpLicense"
                                                        validateForm={validateForm}
                                                        taxReturn={taxReturn}
                                                    />
                                                </Col>
                                            </Row>
                                        </Div>}
                                    </Div>}
                                </Div>

                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.children"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.children && <Div className="subForm">
                                        <h3>
                                            <UniCheckbox
                                                name="sections.secondParentClaim"
                                                validateForm={validateForm}
                                                placeholder={true}
                                                taxReturn={taxReturn}
                                            />
                                        </h3>
                                        {values.sections.secondParentClaim && <Div>
                                            <FieldArray name="deductibles.children">
                                                {(arrayHelpers) => {
                                                    if (values.deductibles.children.length === 0) {
                                                        arrayHelpers.push({ firstName: '', lastName: '', birthNumber: '', numberOfMonths: '', ztpLicense: ''});
                                                    }
                                                    return <Div className="cleared">
                                                        {values.deductibles.children.map((employment, index) => (
                                                        <Div key={index}>
                                                            <Row>
                                                                {index > 0 && <RemoveLink
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}>
                                                                    <FontAwesomeIcon icon={faTimesCircle} style={{color: theme.colors.blue}}/>
                                                                </RemoveLink>}
                                                                <Col xs="12" sm="6" lg="4">
                                                                    <UniField name={`deductibles.children[${index}].firstName`} taxReturn={taxReturn}
                                                                              textKey="deductibles.children.firstName"
                                                                              required={values.sections.children && values.sections.secondParentClaim}
                                                                    />
                                                                </Col>
                                                                <Col xs="12" sm="6" lg="4">
                                                                    <UniField name={`deductibles.children.${index}.lastName`} taxReturn={taxReturn}
                                                                              textKey="deductibles.children.lastName"
                                                                              required={values.sections.children && values.sections.secondParentClaim}
                                                                    />
                                                                </Col>
                                                                <Col xs="12" sm="6" lg="4">
                                                                    <UniField name={`deductibles.children.${index}.birthNumber`} taxReturn={taxReturn}
                                                                              textKey="deductibles.children.birthNumber"
                                                                              required={values.sections.children && values.sections.secondParentClaim}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs="12" sm="6" lg="4">
                                                                    <NumberOfMonths
                                                                        name={`deductibles.children.${index}.numberOfMonths`}
                                                                        textKey="deductibles.children.numberOfMonths"
                                                                        value={values.deductibles.children[index].numberOfMonths}
                                                                        required={values.sections.children && values.sections.secondParentClaim}
                                                                        validateForm={validateForm}
                                                                        taxReturn={taxReturn}
                                                                    />
                                                                </Col>
                                                                <Col xs="12" sm="6" lg="4">
                                                                    <UniCheckbox
                                                                        name={`deductibles.children.${index}.ztpLicense`}
                                                                        validateForm={validateForm}
                                                                        textKey="deductibles.children.ztpLicense"
                                                                        taxReturn={taxReturn}
                                                                    />
                                                                </Col>

                                                            </Row>
                                                        </Div>))}
                                                        <AddLink
                                                            onClick={() => arrayHelpers.push({ firstName: '', lastName: '', birthNumber: '', numberOfMonths: '', ztpLicense: ''})}
                                                        >
                                                            <FontAwesomeIcon icon={faPlusCircle} style={{color: theme.colors.blue}} />{' '}
                                                            {t('deductibles.children.add.label')}
                                                        </AddLink>
                                                    </Div>
                                                }}
                                            </FieldArray>
                                        </Div>}
                                    </Div>}
                                </Div>
                            </Div>}

                            {!canDeduct() && <p><strong>
                                {t('nonCzTaxResident.noDeductibles.label')}
                            </strong></p>}

                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="sections.kindergarten"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.sections.kindergarten && <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6" lg="4">
                                            <UniField name="deductibles.kindergartenCount" required={values.sections.kindergarten} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6" lg="4">
                                            <UniField name="deductibles.kindergarten" required={values.sections.kindergarten} taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                </Div>}
                            </Div>

                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="sections.student"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.sections.student && <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6" lg="4">
                                            <NumberOfMonths
                                                name="deductibles.student"
                                                value={values.deductibles.student}
                                                required={values.sections.student}
                                                validateForm={validateForm}
                                                taxReturn={taxReturn}
                                            />
                                        </Col>
                                    </Row>
                                </Div>}
                            </Div>


                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="sections.paidAdvances"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.sections.paidAdvances && <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6" lg="4">
                                            <UniField name="deductibles.paidAdvances" required={values.sections.paidAdvances} taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                </Div>}
                            </Div>

                            <Row>
                                <Col xs="12" sm="6" lg="4">
                                    <Button onClick={() => wizardService.backToDashboard()}>
                                        <FontAwesomeIcon icon={faHome} />{' '}
                                        {t('buttons.backToDashboard.label')}
                                    </Button>
                                </Col>
                                <Col xs="12" sm="6" lg="4">
                                    <Button onClick={() => wizardService.previousStep()}>
                                        <FontAwesomeIcon icon={faArrowCircleLeft} />{' '}
                                        {t('buttons.previous.label')}
                                    </Button>
                                </Col>
                                <Col xs="12" sm="6" lg="4">
                                    <Button className="btn-primary" onClick={() => wizardService.nextStep(validateForm, setFieldTouched, t)}>
                                        {t('buttons.next.label')}{' '}
                                        <FontAwesomeIcon icon={faArrowCircleRight} />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Div>
                )}
            </Formik>
		)
	}
}

const AddLink = glamorous(Span)({
    color: theme.colors.blue,
    cursor: 'pointer',
});

const RemoveLink = glamorous(Div)({
    position: 'absolute',
    marginLeft: '-8px',
    cursor: 'pointer',
});

export default translate('translations')(StepThreeForm);
