import React from 'react';
import { toast } from 'react-toastify';
import uuid from 'uuid/v4';
import Spinner from 'react-svg-spinner';
import { Div } from 'glamorous';

const messagesConstants = {
	TYPE_INFO: 'info',
	TYPE_SUCCESS: 'success',
	TYPE_WARNING : 'warning',
	TYPE_ERROR : 'error',
	TYPE_DEFAULT : 'default',
};

const flashMessages = {
	addMessage,
	updateMessage,
	removeMessage,
	clearAllMessages,
	messagesConstants,
};

export function addMessage(content, type, messageId = null, options = {}) {
	toast[type](content, {
		position: type === messagesConstants.TYPE_ERROR ? 'top-center' : 'bottom-right',
		toastId: messageId ? messageId : uuid(),
		...options
	});
}

export function addLoadingMessage(content, messageId, options) {
	const component = (
		<Div display="flex" alignItems="center">
			<Div><Spinner size="32px" /></Div>
			<Div marginLeft="15px">{content}</Div>
		</Div>
	);

	addMessage(component, messagesConstants.TYPE_INFO, messageId, {
		autoClose: false,
		...options
	});
}

export function updateMessage(messageId, options = {}) {
	toast.update(messageId, options);
}

export function removeMessage(messageId) {
	toast.dismiss(messageId);
}

export function clearAllMessages() {
	toast.dismiss();
}

export { messagesConstants };

export default flashMessages;