import apiService from '../../services/apiService';
import { API } from '../../utils/apiConstants';

export const catalogService = {
    load,
};

function load(name: string, lang = 'en') {
    return apiService.get(`${API.CATALOGS}/${name}/${lang}`)
        .catch(() => {
            return [];
        });
}
